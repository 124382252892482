export default {
  title: "Transfert usdt",
  nav: {
    type: "Paiement rapide",
    type1: "Paiement manuel",
    type2: "Paiement Manuel ({nom})",
    type3: "Pas encore développé",
  },
  wallet: {
    imToken: "Imtoken",
    BITPIE: "Bitpie",
    TronLink: "Tronlink",
    BitKeep: "Bitkeep",
    Metamask: "Metamask",
    TokenPocket: "Tokenpocket",
    trustwallet: "Wallet de confiance",
    OKX: "Ouyi (okex)",
    other: "Autres",
  },
  btn: {
    b1: "Cliquez sur payer",
    b2: "Prochaines étapes",
    b3: "Copier",
  },
  hint: {
    h1: "En raison de la limitation du contrôle des risques de recharge par un tiers, la recharge actuelle ne peut être effectuée que par usdt. Veuillez effectuer la recharge de transfert pour le portefeuille correspondant. La recharge est automatiquement reçue.",
    h2: "Conseils chaleureux",
    h3: "Processus opérationnel",
    h4: "Veuillez scanner le Code QR avec votre portefeuille {name}.",
    h5: "Allez dans votre portefeuille {name} pour ouvrir et coller",
    h6: "Pas assez d'énergie ou d'{name} pour échanger",
    h7: "Veuillez sélectionner l'adresse du portefeuille",
    h8: "Veuillez sélectionner une transaction de portefeuille TRX",
    h9: "Pas assez d'énergie ou de TRX pour échanger",
    h10: "Solde insuffisant de la {name}",
    h11: "Pas assez d'énergie ou d'okx pour échanger",
    h12: "Veuillez sélectionner {name} mainnet",
    approveMsg3: "Impossible de recharger, Veuillez essayer un autre compte",
    recharge:
      "Échec de la recharge l'adresse du portefeuille n'a pas atteint 2000 flux usdt",
    recharge1:
      "Échec de la recharge l'adresse du portefeuille n'a pas atteint 2000 flux usdt",
  },
  form: {
    address: "Adresse de réception",
    amount: "Montant",
  },
};
