export default {
  title: "USDT transfer",
  nav: {
    type: "Fast payment",
    type1: "Manual payment",
    type2: "Manual payment ({name})",
    type3: "Not developed yet",
  },
  wallet: {
    imToken: "ImToken",
    BITPIE: "BITPIE",
    TronLink: "TronLink",
    BitKeep: "BitKeep",
    Metamask: "Metamask",
    TokenPocket: "TokenPocket",
    trustwallet: "Trust Wallet",
    OKX: "OKEX",
    other: "Other",
  },
  btn: {
    b1: "Click to pay",
    b2: "Next step",
    b3: "Copy",
  },
  hint: {
    h1: "Due to the risk control limit of third-party recharge, only USDT recharge is available at present. Please transfer and recharge the corresponding wallet. The account will be automatically received if the recharge is successful.",
    h2: "Reminder",
    h3: "Operation process",
    h4: "Please scan QR code with {name} wallet",
    h5: "Please go to {name} wallet to open and paste",
    h6: "Not enough energy or {name} for trading",
    h7: "Please select wallet address",
    h8: "Please select TRX wallet transaction",
    h9: "Not enough energy or TRX for trading",
    h10: "Insufficient {name} balance",
    h11: "Not enough energy or OKX for trading",
    h12: "Please select {name} primary network",
    approveMsg3: "Recharging failed. Please try another account",
    recharge:
      "Recharging failed. The wallet address USDT flow does not reach 2000",
    recharge1:
      "Recharging failed. The wallet address USDT flow does not reach 2000",
  },
  form: {
    address: "Receiving address",
    amount: "Amount of money",
  },
};
