export default {
  title: "Transferência USDT",
  nav: {
    type: "Pagamento rápido",
    type1: "Pagamento manual",
    type2: "Pagamento manual ({name})",
    type3: "Ainda não desenvolvido",
  },
  wallet: {
    imToken: "ImToken",
    BITPIE: "BITPIE",
    TronLink: "TronLink",
    BitKeep: "BitKeep",
    Metamask: "Metamáscara",
    TokenPocket: "TokenPocket",
    trustwallet: "Carteira de Confiança",
    OKX: "OKEX",
    other: "Outros",
  },
  btn: { b1: "Clique para pagar", b2: "Próximo passo", b3: "Cópia" },
  hint: {
    h1: "Devido ao limite de controle de risco de recarga de terceiros, apenas recarga USDT está disponível no momento. Por favor, transfira e recarregue a carteira correspondente. A conta será recebida automaticamente se a recarga for bem sucedida.",
    h2: "Lembrete",
    h3: "Processo de operação",
    h4: "Por favor, digitalize o código QR com a carteira {name}",
    h5: "Vá para a carteira {name} para abrir e colar",
    h6: "Energia insuficiente ou {name} para negociação",
    h7: "Seleccione por favor o endereço da carteira",
    h8: "Seleccione por favor a transacção da carteira TRX",
    h9: "Não há energia suficiente ou TRX para negociação",
    h10: "Saldo {name} insuficiente",
    h11: "Não há energia suficiente ou OKX para negociação",
    h12: "Seleccione por favor a rede primária {name}",
    approveMsg3: "A recarga falhou. Tente outra conta",
    recharge:
      "A recarga falhou. O fluxo USDT do endereço da carteira não atinge 2000",
    recharge1:
      "A recarga falhou. O fluxo USDT do endereço da carteira não atinge 2000",
  },
  form: { address: "Endereço de recepção", amount: "Montante de dinheiro" },
};
