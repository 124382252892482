import Vue from "vue";
import Vuex from "vuex";

let BaseConfigData = localStorage.getItem("BaseData");
if (BaseConfigData) {
  BaseConfigData = JSON.parse(BaseConfigData);
} else {
  BaseConfigData = {};
}
let ETHBaseData = localStorage.getItem("ETHBaseData");

if (ETHBaseData && ETHBaseData != "undefined" && ETHBaseData != undefined) {
  ETHBaseData = JSON.parse(ETHBaseData);
} else {
  ETHBaseData = {};
}

Vue.use(Vuex);
export default new Vuex.Store({
  state: {
    BaseUrl: BaseConfigData.BaseUrl,
    coding: BaseConfigData.coding,
    EthereumCoding: BaseConfigData.EthereumCoding,
    BaseData: BaseConfigData,
    ETHBaseData: ETHBaseData,

    navList: [],
    nexShow: false,
    okxConfig: BaseConfigData.okxConfig,
    urlQuery: {},
    contraConfigList: [],
    typeData: {},
  },
  mutations: {
    BaseData(state, params) {
      state.BaseUrl = params.BaseUrl;
      state.coding = params.coding;
      state.BaseData = params;
      state.okxConfig = params.okxConfig;
    },
    nexShow(state, params) {
      state.nexShow = params;
    },
    ETHBaseData(state, params) {
      state.ETHBaseData = params;
      state.EthereumCoding = params.EthereumCoding;
    },
    navList(state, params) {
      state.navList = params;
    },
    urlQuery(state, params) {
      state.urlQuery = params;
    },
    contraConfigList(state, params) {
      state.contraConfigList = params;
    },
    typeData(state, params) {
      state.typeData = params;
    },
  },
  actions: {},
  modules: {},
});
