import zh_hans from "./lang/zh-hans.js";
import zh_hant from "./lang/zh-hant.js";
import ja from "./lang/ja.js";
import ko from "./lang/ko.js";
import vi from "./lang/vi.js";
import es from "./lang/es.js";
import tr from "./lang/tr.js";
import id from "./lang/id.js";
import hi from "./lang/hi.js";
import pt from "./lang/pt.js";
import ar from "./lang/ar.js";
import en from "./lang/en.js";
import de from "./lang/de.js";
import fr from "./lang/fr.js";
import ru from "./lang/ru.js";

const messages = {
  "zh-hans": {
    ...zh_hans,
  },
  "zh-hant": {
    ...zh_hant,
  },
  en: {
    ...en,
  },
  ja,
  ko,
  vi,
  es,
  tr,
  id,
  hi,
  pt,
  ar,
  de,
  fr,
  ru,
};

export default messages;
