export default {
  title: "Перевод USDT ",

  nav: {
    type: " быстрая оплата ",
    type1: " оплата вручную ",
    type2: " оплата вручную ({name}) ",
    type3: " пока не освоено ",
  },
  wallet: {
    imToken: " ImToken ",
    BITPIE: " BITPIE ",
    TronLink: " тронлинк ",
    BitKeep: " BitKeep ",
    Metamask: " Metamask ",
    TokenPocket: " TokenPocket ",
    trustwallet: " Trust Wallet ",
    OKX: " ойи (окекс) ",
    other: "Прочее",
  },
  btn: {
    b1: "Cliquez sur payer",
    b2: "Prochaines étapes",
    b3: "Copier",
  },
  hint: {
    b1: " Щелчок на платеже ",
    b2: " Следующие шаги ",
    b3: " копировать ",
    h1: " В настоящее время только USDT может быть загружен из - за ограничений на загрузку, введенных третьими сторонами, пожалуйста, переведите сумму соответствующего кошелька.  полная сумма была получена автоматически. ",
    h2: " подсказка ",
    h3: " оперативный процесс ",
    h4: " Просканируйте двухмерный код бумажника {name} ",
    h5: " обратитесь к папке {name} для открытия бумажника ",
    h6: " Недостаточно энергии или {name} для торговли",
    h7: " Выберите адрес бумажника ",
    h8: " Выберите операцию с бумажником TRX ",
    h9: " Недостаточно энергии или трих для торговли ",
    h10: " дефицит {name} ",
    h11: " не хватает энергии или Oxx для торговли ",
    h12: "Выберите главную сеть {name}",
    approveMsg3: " Невозможно добавить, попробуйте сменить счёт ",
    recharge: " Не удалось загрузить адрес бумажника ",
    recharge1: " Не удалось загрузить адрес бумажника ",
  },
  form: {
    address: " адрес инкассации ",
    amount: " Общая сумма",
  },
};
