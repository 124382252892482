<!--  -->
<template>
  <div class="terms">
    <h1>《TokenPocket Terms of Service》</h1>
    <h2>Last Updated: May 29, 2021.</h2>
    <p>
      Dear User,<br />
      Thank you for choosing TokenPocket. This TokenPocket Terms of Service
      (“Agreement”) is made between you (“you” or “User”) and TokenPocket
      Foundation Ltd. (“TPF” or “we”) and is legally binding between you and
      TPF.
      <br /><br />
      TPF hereby reminds you that you must carefully read the full content of
      this Agreement and other documents mentioned in this Agreement before
      using TokenPocket (“TokenPocket” or “App”). Particularly, you must
      carefully read the section of “Disclaimer and Limitation of Liability” and
      other sections which are displayed in bold. You must make sure that you
      fully understand the whole Agreement and evaluate the risks of using
      TokenPocket on your own. In addition, TokenPocket can be downloaded on
      mobile application platforms, including but not limited to Google Play and
      App Store.
      <br /><br />
    </p>
    <h3>I. Confirmation and Acceptance of this Agreement</h3>
    <p>
      1.1 You understand that this Agreement and other relevant documents apply
      to TokenPocket and the Decentralized Applications (“DApps”) which are
      developed and owned independently by TPF on TokenPocket (and excluding
      DApps developed by third parties).<br />
      1.2 After you download TokenPocket and start to create or import wallet,
      you are deemed as having read and accepted this Agreement, which shall
      cause this Agreement to become effective and legally binding on both you
      and TPF immediately.<br />
      1.3 TPF may, at its sole discretion, modify or replace this Agreement at
      any time. The modified Agreement will automatically take effect once it is
      posted on TokenPocket and you will not be notified separately. If you do
      not agree with the modifications, you shall cease to use TokenPocket
      immediately. Use of TokenPocket by you after any modification to this
      Agreement constitutes your acceptance of this Agreement as modified.<br />
      1.4 If you are under 18 years old or you are a person of no capacity for
      civil acts or a person of limited capacity for civil acts, please use
      TokenPocket under the guidance of your parents or guardians.<br />
      <br />
    </p>
    <h3>II. Definition</h3>
    <p>
      2.1 <b>TokenPocket</b>: The blockchain wallet developed by TPF based on
      BTC/ETH/BSC/HECO/TRON/Polkadot/Kusama/EOS/COSMOS/BOS/ENU/MOAC/IOST and
      other blockchain systems which TokenPocket may support in the future, and
      other supporting tools which are developed for the convenience of the
      Users when using blockchain systems.<br />
      2.2 <b>User</b>: a) a User must be a natural person who possesses full
      capacity for civil acts; b) if you are under 18 years old, please use
      TokenPocket under the guidance of your parents or guardians. If any person
      of no capacity for civil acts conducts any transactions on TokenPocket or
      any person of limited capacity for civil acts conducts any transaction
      which does not commensurate his/her civil rights or act capacity, the
      parents or guardians of the User shall be liable for the consequences of
      such transactions.<br />
      2.3
      <b
        >Excluded Person: a) except for natural persons, persons who have the
        legal and consciousness ability to conclude this Agreement; or b) users
        who are prohibited, restricted, unauthorized or unqualified to use the
        service (as defined in this Agreement) ) in any form or method (in whole
        or in part) due to this Agreement, laws, regulatory requirements, or the
        provisions of the jurisdiction applicable to the user. For the avoidance
        of doubt, Chinese Users are also regarded as "Excluded Person".</b
      ><br />
      2.4 <b>Terms</b>:<br />
      &nbsp;&nbsp;&nbsp;&nbsp; a) <b>Create or import wallet</b>: Using
      TokenPocket to create or import wallet after you accept this Agreement.<br />

      &nbsp;&nbsp;&nbsp;&nbsp; b) <b>Wallet Password</b>: The password you set
      when you create the wallet. The Wallet Password will be used to encrypt
      and protect your Private Key. TokenPocket, as a decentralized application,
      will not store your Wallet Password on our servers, nor will your Wallet
      Password be stored in your own mobile devices. If you lose or forget your
      Wallet Password, you will have to reset the Wallet Password with your
      Private Key or Mnemonic Words.<br />
      Reminder: It is recommended that Users follow the relevant steps for the
      information prompts involved in the TokenPocket operating interface.<br />
      &nbsp;&nbsp;&nbsp;&nbsp; c) <b>Specific Users</b>: Users who should
      cooperate with TPF and disclose Personal Information in order to comply
      with the laws, regulations and policies of Singapore.<br />
      &nbsp;&nbsp;&nbsp;&nbsp; d) <b>Private Key</b>: Consists of 256 random
      bits. Private Key is the core for the User to hold and use the Tokens.<br />
      &nbsp;&nbsp;&nbsp;&nbsp; e) <b>Public Key</b>: Public key is derived from
      the Private Key based on cryptography and is used to generate wallet
      addresses. A wallet address is a public address for reception of
      Tokens.<br />
      &nbsp;&nbsp;&nbsp;&nbsp; f) <b>Mnemonic Words</b>: Consists of 12 (or
      15/18/21/24) words which are randomly generated, and it is based on BIP39,
      the industry standard of blockchain. It is a human readable format of
      words to back up your Private Key for recovery.<br />
      &nbsp;&nbsp;&nbsp;&nbsp; g) <b>Keystore</b>: A file that is encrypted by
      Private Key or Mnemonic Words and protected by the User’s Wallet Password.
      Keystore is stored only in Users’ mobile device and will not be
      synchronized to TPF servers.<br />
      &nbsp;&nbsp;&nbsp;&nbsp; h) <b>Tokens</b>: The tokens which are supported
      by TokenPocket currently, including but not limited to ETH, EOS and so
      on.<br />
      &nbsp;&nbsp;&nbsp;&nbsp; i) <b>Materials</b>: Contents in the columns of
      “News”, etc. on TokenPocket. The Materials are TPF’ proprietary
      properties. User shall not reproduce or distribute the materials without
      TPF’ permission and authorization.<br />
      &nbsp;&nbsp;&nbsp;&nbsp; j) <b>Personal Information</b>: Means information
      recorded in electronic or any other form which may identify a natural
      person when used alone or in combination with other information, including
      but not limited to name, date of birth, identification card number,
      personal biological identification information, address, telephone number,
      bank card number, email address, wallet address, mobile device
      information, operation record, transaction record, but excluding Wallet
      Password, Private Key, Mnemonic Words and Keystore.<br />
      &nbsp;&nbsp;&nbsp;&nbsp; k) <b>PRC</b>: The People’s Republic of China,
      including Hong Kong, Macau and Taiwan.<br />
      &nbsp;&nbsp;&nbsp;&nbsp; l) <b>Third-party services</b>: Products and
      services provided by third parties such as third-party DApps, third-party
      DeFi, third-party smart contracts, third-party open source agreements,
      third-party web pages, third-party hardware wallets, third-party online
      web wallets, third-party exchanges, etc.<br />
      <br />
    </p>
    <h3>III. Services</h3>
    <p>
      3.1 Create or import wallet. You may use TokenPocket to create a new
      wallet or import wallets generated by other wallet application. You may
      only import wallets with Tokens which are supported by TokenPocket.<br />
      3.2 Transfer and receive Tokens. You may manage your digital Tokens by
      using the transfer and reception functions of TokenPocket, i.e., you may
      revise the blockchain ledger by signing with your Private Key. Tokens
      Transfer means the payer transfer the Token to the blockchain address of
      the payee. The actual transfer of Tokens happens on the blockchain system
      (instead of on TokenPocket).<br />
      3.3 Observe the market. You may use TokenPocket to observe the market of
      the Tokens supported by TokenPocket. The quotation of each Token is
      captured by TokenPocket from corresponding exchange and is displayed in
      the “Markets” column on TokenPocket.<br />
      3.4 Manage Tokens. You may use TokenPocket to add, manage or delete the
      Tokens supported by TokenPocket.<br />
      Browse DApps. Users may use TokenPocket to visit and use the services
      provided by DApps (including DApps developed by TPF and DApps developed by
      third parties).<br />
      3.5 Browse the DApp. Users can link to the DApp and use the services
      provided by the DApp (including TPF own DApps and third-party DApps)
      through the link on TokenPocket.<br />
      3.6 Transaction records. We will copy all or part of your transaction
      records from the blockchain system. However, Users shall refer to the
      blockchain system for the latest transaction records.<br />
      3.7 Suspension of service. You understand that we are not able to reverse
      or cancel the transaction because transactions based on blockchain
      technologies are irrevocable. However, under certain circumstances, we may
      suspend or limit the function of TokenPocket used by a particular User.<br />
      3.8 The mobile phone number, email address, and the added Owner’s
      authority required in the registration of TokenPocket (the effective
      decentralized solution under the EOS system) belong to TPF. We will issue
      you a special note when the registration begins. If you choose to use the
      function, you are treated as having agreed to and recognized all terms.<br />
      During the registration using the TokenPocket mobile phone number and
      email address (the effective decentralized solution under the EOS system),
      your mobile phone or email address will be collected as a voucher. The
      multiple-signature authority of Owner will be added by default to
      facilitate the retrieval of the private key.<br />
      In the registration method using the mobile phone and email address (the
      effective decentralized solution under the EOS system), we will impose the
      multiple-signature setting to TPF’s Owner authority to safeguard users’
      authority security to the greatest extent. Additionally, the block
      information is traceable, which enables the largest openness and
      transparency. The initiator of every operation on the account can be
      checked.<br />
      3.9 Token exchange. Users can exchange tokens with third-party smart
      contracts or third-party DEXs. As an interface tool, TokenPocket is used
      to help users interact with third parties and display the corresponding
      results of the token exchange.<br />
      3.10 Other services that TPF would like to provide.<br />
      <br />
    </p>
    <h3>IV. Users who use TokenPocket must understand that:</h3>
    <p>
      <b>
        4.1 In order to keep the decentralization feature of blockchain and to
        protect the security of your digital Tokens, TPF offers decentralized
        service which is largely different from the banking and financial
        institutions. Users shall understand that TPF (the decentralized
        solution) DOES NOT provide the following services:<br />
        &nbsp;&nbsp;&nbsp;&nbsp; a) store Users’ Wallet Password (the password
        Users set when creating or importing wallets), Private Key, Mnemonic
        Words or Keystore;<br />
        &nbsp;&nbsp;&nbsp;&nbsp; b) restore Users’ Wallet Password, Private Key,
        Mnemonic Words or Keystore;<br />
        &nbsp;&nbsp;&nbsp;&nbsp; c) freeze the wallet;<br />
        &nbsp;&nbsp;&nbsp;&nbsp; d) report the loss of wallet;<br />
        &nbsp;&nbsp;&nbsp;&nbsp; e) restore the wallet;<br />
        &nbsp;&nbsp;&nbsp;&nbsp; f) rollback transactions.<br />
        4.2 Users shall take care of their mobile devices, back up the
        TokenPocket App, and back up the Wallet Password, Mnemonic Words,
        Private Key and Keystore by themselves. If your mobile device is lost,
        your TokenPocket App or your wallet is deleted and not backed up, your
        wallet is stolen or you forget your Wallet Password, Private Key,
        Mnemonic Words or Keystore, TPF is not able to recover the wallet or
        restore Wallet Password, Private Key, Mnemonic Words or Keystore. Nor
        can TPF cancel transactions for the mishandling of Users (such as typing
        in wrong addresses for transactions).<br />
        4.3 TokenPocket does not support all existing Tokens. Do not use
        TokenPocket to handle any non-supported Tokens.<br />
        4.4 TokenPocket is only a tool for Users to manage their Tokens and is
        not an exchange or a trading platform. For the purpose of this
        Agreement, the word “transactions” only means transferring and receiving
        Tokens, which is substantially different from transactions on the
        exchanges and trading platforms.<br />
        4.5 The DApps integrated into TokenPocket include those developed
        independently by TPF and those developed by third parties. TokenPocket
        only acts as a blockchain browser for those third-party-developed DApps.
        Users shall, at their sole discretion, decide whether there would be any
        risks to accept the services provided by or to conduct transactions on
        the third-party-developed DApps.<br />
        <br
      /></b>
    </p>
    <h3>V. Your Rights and Obligations</h3>
    <p>
      <b> 5.1 Create or Import Wallet<br /></b> &nbsp;&nbsp;&nbsp;&nbsp; a)
      Create or import wallet: you are entitled to use TokenPocket on your
      mobile device to create and/or import wallet, set Wallet Password and use
      your wallet on TokenPocket to transfer and receive Tokens on
      blockchain.<br />
      &nbsp;&nbsp;&nbsp;&nbsp; b) Identification verification: Specific Users
      will be asked to complete identification verification before using
      TokenPocket to comply with related laws and regulations, according to the
      notification of TokenPocket. Specific Users may be asked to provide
      Personal Information including but not limited to name, identification
      card number, cell phone number, bank card information, etc., without which
      the Specific Users will not be able to use certain services and the
      Specific Users alone shall be responsible for the loss caused by their
      delay in completing the verification.<br />
      &nbsp;&nbsp;&nbsp;&nbsp; c) TPF may develop different versions of
      TokenPocket for different terminal devices. You shall download and install
      applicable version. If you download and install TokenPocket or other
      application with the same name as “TokenPocket” from any unauthorized
      third party, TPF cannot guarantee the normal operation or security of such
      application. Any loss caused by using such application shall be borne by
      you.<br />
      &nbsp;&nbsp;&nbsp;&nbsp; d) A previous version of TokenPocket may stop to
      operate after a new version is released. TPF cannot guarantee the
      security, continuous operation or customer services for the previous
      version. Users shall download and use the latest version.<br />
      <b>5.2 Use of TokenPocket<br /></b>
      &nbsp;&nbsp;&nbsp;&nbsp; a) Users shall take care of their mobile devices,
      Wallet Password, Private Key, Mnemonic Words and Keystore by themselves.
      TPF does not store or hold the above information for Users. You shall be
      responsible for any risks, liabilities, losses and expenses which result
      from frauds, you losing your mobile device, disclosing (whether actively
      or passively) or forgetting Wallet Password, Private Key, Mnemonic Words
      or Keystore, or your wallet being attacked.<br />
      &nbsp;&nbsp;&nbsp;&nbsp; b) Follow the Alert. You understand and agree to
      follow the Alert pushed by TokenPocket. You shall be responsible for any
      risks, liabilities, losses and expenses which result from your failure to
      comply with the Alert.<br />
      &nbsp;&nbsp;&nbsp;&nbsp; c) You understand that TokenPocket undertakes no
      responsibility to conduct due diligence on the services or transactions
      provided by third-party-developed DApps. You shall make investment
      decisions rationally and assume the risks by yourself.<br />
      &nbsp;&nbsp;&nbsp;&nbsp; d) Complete the identification verification. If
      TokenPocket reasonably deems your operation or transactions to be
      abnormal, or considers your identification to be doubtful, or TokenPocket
      considers it necessary to verify your identification documents or other
      necessary documents, you shall cooperate with TokenPocket and provide your
      valid identification documents or other necessary documents and complete
      the identification verification in time.<br />
      &nbsp;&nbsp;&nbsp;&nbsp; e) With the registration method using Tokenpocket
      mobile phone number and email address (the effective decentralized
      solution under the EOS system), you can choose to export the private key
      functions to better manage your account. You are also informed that you
      can delete the multiple-signature Owner authority added by default and
      that TPF can no longer help you recover the private key if the authority
      aforementioned is deleted.<br />
      <b
        >5.3 Transfer of Tokens<br />
        &nbsp;&nbsp;&nbsp;&nbsp; a) You understand that you may be subject to
        daily limits on the amount and times of transfers according to your
        location, regulatory requirements, transferring purposes, risk control
        by TokenPocket, or identification verification.<br />
        &nbsp;&nbsp;&nbsp;&nbsp; b) You understand that blockchain operations
        are “irrevocable”. When you use TokenPocket to transfer Tokens, you
        shall be responsible for the consequences of your mishandling of the
        transfer (including but not limited to wrong address, problems of the
        node servers selected by you).<br />
        &nbsp;&nbsp;&nbsp;&nbsp; c) You understand that the following reasons
        may result in “transfer failed” or “mining overtime”<br />
        &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; a. insufficient
        balance in wallet;<br />
        &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; b. insufficient gas
        for transaction;<br />
        &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; c. blockchain’s
        failure to execute the code of smart contracts;<br />
        &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; d. the transfer amount
        exceeds the transfer limits imposed by authorities, TokenPocket or laws
        or regulations;<br />
        &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; e. technical failure
        of the network or equipment;<br />
        &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; f. abandoned
        transactions result from blockchain network congestion or failure;<br />
        &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; g. the wallet address
        of yours or your counterparty’s is identified as special addresses, such
        as high-risk address, exchange address, ICO address, Token address
        etc.<br />
        &nbsp;&nbsp;&nbsp;&nbsp; d) You understand that TokenPocket is only a
        tool for transfer of Tokens. TPF shall be deemed to have fulfilled its
        obligations once you have finished the transfer and shall not be held
        liable for any other disputes.<br />
        &nbsp;&nbsp;&nbsp;&nbsp; e) Compliance. You understand that you shall
        abide by relevant laws, regulations and policies when you use
        TokenPocket or the DApps on TokenPocket.<br />
        &nbsp;&nbsp;&nbsp;&nbsp; f) Notifications. TokenPocket may send
        notifications to you by web announcements, e-mails, text messages, phone
        calls, Notification Centre information, popup tips or client-end notices
        (e.g., information about your transfer or suggestions on certain
        operations) which you shall be aware of timely.<br
      /></b>
      <b>5.4 Service fees and taxes.<br /></b>
      &nbsp;&nbsp;&nbsp;&nbsp; a) TokenPocket does not charge you any service
      fees or handling fees for the time being. TokenPocket may reach an
      agreement with you or announce rules regarding service fees in the
      future;<br />
      &nbsp;&nbsp;&nbsp;&nbsp; b) You need to pay gas when you transfer Tokens,
      the amount of which would be on your sole discretion and would be
      collected by certain blockchain system;<br />
      &nbsp;&nbsp;&nbsp;&nbsp; c) You understand that under some specific
      circumstances, your transfer of Tokens may fail due to instable network,
      but you may still be charged gas by certain blockchain system;<br />
      &nbsp;&nbsp;&nbsp;&nbsp; d) You shall bear all the applicable taxes and
      other expenses occurred due to your transactions on TokenPocket.<br />
      <br />
    </p>
    <h3>VI. Risks</h3>
    <p>
      <b>
        6.1 You understand and acknowledge that the blockchain technology is a
        field of innovation where the laws and regulations are not fully
        established. You may be faced with material risks including instability
        of technology or failure of Tokens redemption. You also understand that
        Tokens have much higher volatility comparing to other financial assets.
        You shall make investment decisions and hold or dispose of the Tokens in
        a reasonable way and corresponding to your financial status and risk
        preferences. You also acknowledge that the market information is
        captured from exchanges by TokenPocket and may not represent the latest
        or the best quotation of each Token.<br />
        6.2 If you or your counterparty fails to comply with this Agreement or
        fails to follow the instructions, tips or rules on the website or on the
        page of the transaction or payment, TokenPocket does not guarantee
        successful transfer of the Tokens and TokenPocket shall not be held
        liable for any of the consequences of such failure. If you or your
        counterparty has already received the payment in TokenPocket wallet or
        third-party wallet, you understand that transactions on blockchain are
        irreversible and irrevocable. You and your counterparty shall assume the
        liabilities and consequences of your transactions.<br />
        6.3 You understand that blockchain operations and related transactions
        are irreversible. TokenPocket shall not be held liable for the
        corresponding risks and consequences.<br />
        6.4 When you use third-party-developed DApps integrated in TokenPocket,
        TPF strongly suggest you read this Agreement and TokenPocket’s Alert
        carefully, get familiar with the counterparty and the product
        information and evaluate the risks before you make transactions on such
        DApps.<br />
        6.5 You understand that such transactions and corresponding contractual
        relationship are between you and your counterparty. TokenPocket shall
        not be held liable for any risks, responsibilities, losses or expenses
        occurred due to such transactions.<br />
        6.6 It is your sole responsibility to make sure that your counterparty
        is a person with full capacity for civil acts and decide whether you
        shall transact with him/her.<br />
        6.7 You shall check the official blockchain system or other blockchain
        tools when you receive Alert such as “transaction failed” or “mining
        overtime” in order to avoid repetitive transfer. If you fail to follow
        this instruction, you shall bear the losses and expenses occurred due to
        such repetitive transfer.<br />
        6.8 You understand that after you create or import wallet on
        TokenPocket, your Keystore, Private Key and Mnemonic Words are only
        stored on your mobile device and will not be stored in TokenPocket or on
        the servers of TPF. You may change another mobile device to use
        TokenPocket after you follow the instructions on TokenPocket to backup
        your wallet. If you lose your mobile device before you could write down
        or backup your Wallet Password, Private Key, Mnemonic Words or Keystore,
        you may lose your Tokens and TPF is unable to restore them. If your
        Wallet Password, Private Key, Mnemonic Words or Keystore is disclosed or
        the device which stores or holds your Wallet Password, Private Key,
        Mnemonic Words or Keystore is hacked or attacked, you may lose your
        Tokens and TPF is unable to restore them. You shall bear the foregoing
        losses on your own.<br />
        6.9 We suggest you backup your Wallet Password, Private Key, Mnemonic
        Words and Keystore when you create or import wallet by writing them down
        on papers or backup them in password management apps. Please do not use
        electronic methods such as screenshots, e-mails, note-taking apps in
        cell phones, text messages, WeChat or QQ to backup any of the above
        information.<br />
        6.10 In order to avoid potential security risks, we suggest you use
        TokenPocket in a secured network environment. Please do not use a
        jailbreak or Rooted mobile device.<br />
        6.11 Please be alert to frauds when you use TokenPocket. If you find any
        suspicious conducts, we encourage you to inform us immediately.<br />
        <br
      /></b>
    </p>
    <h3>VIl. Change, Suspension, Termination of TPF Service</h3>
    <p>
      <b>
        7.1 You acknowledge and accept that TPF may, at its sole discretion,
        provide only a part of services for the time being, suspend certain
        services or provide new services in the future. When we change our
        services, your continuous use of TokenPocket is deemed as your
        acceptance of this Agreement and revisions of this Agreement.<br />
        7.2 You understand that TPF may suspend services under the following
        circumstances:<br />
        &nbsp;&nbsp;&nbsp;&nbsp; a) due to the maintenance, upgrading, failure
        of equipment and blockchain system and the interruption of
        communications etc., which lead to the suspension of the operation of
        TokenPocket;<br />
        &nbsp;&nbsp;&nbsp;&nbsp; b) due to force majeure events including but
        not limited to typhoon, earthquake, tsunami, flood, power outage, war,
        or terrorist attacks, or computer viruses, Trojan Horse, hacker attacks,
        system instability or government behaviors and other reasons, TPF is
        unable to provide services or in TPF’ reasonable opinion, continuous
        provision of services would result in significant risks;<br />
        &nbsp;&nbsp;&nbsp;&nbsp; c) due to other events which TPF cannot control
        or reasonably predicate.<br />
        &nbsp;&nbsp;&nbsp;&nbsp; d) TPF reserves the right to unilaterally
        suspend or terminate all or part of the function of TokenPocket under
        the following circumstances:<br />
        &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; a. death of Users;<br />
        &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; b. if you steal
        others’ wallets information or mobile devices;<br />
        &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; c. if you provide
        false Personal Information on TokenPocket or cheat on Questionnaire;<br />
        &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; d. if you refuse to
        allow mandatory update of TokenPocket;<br />
        &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; e. if you use
        TokenPocket to commit illegal or criminal activities;<br />
        &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; f. if you hinder the
        normal use of TokenPocket by other Users;<br />
        &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; g. if you pretend to
        be staff or management personnel of TPF;<br />
        &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; h. if you threaten the
        normal operation of TPF computer system by attack, invasion, alternation
        or any other means;<br />
        &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; i. if you use
        TokenPocket to send spams;<br />
        &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; j. if you spread
        rumors which endanger the goodwill of TPF and TokenPocket;<br />
        &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; k. if you conduct any
        illegal activities, breach this Agreement etc. or other circumstances
        under which TPF reasonably considers necessary to suspend services.<br />
        7.3 You are entitled to export your wallets within a reasonable amount
        of time if TPF changes, suspends or terminates its services.<br />
        <br
      /></b>
    </p>
    <h3>VIIl. Your Representations and Warranties</h3>
    <p>
      <b>
        8.1 You shall comply with all applicable laws and regulations of the
        country or area you reside in. You shall not use TokenPocket for any
        unlawful purposes or by any unlawful means.<br />
        8.2 You shall not use TokenPocket to commit any illegal or unlawful
        activities, including but not limited to:<br />
        &nbsp;&nbsp;&nbsp;&nbsp; a) activities opposing the basic principles set
        forth in the constitution, endangering national security, disclosing
        state secrets, overturning the government or undermining national
        unity;<br />
        &nbsp;&nbsp;&nbsp;&nbsp; b) any illegal conducts, such as money
        laundering, illegal fund raising etc.;<br />
        &nbsp;&nbsp;&nbsp;&nbsp; c) accessing TPF services, collecting or
        processing the content provided by TPF, intervening or attempting to
        intervene any Users, by the employment of any automated programs,
        software, network engines, web crawlers, web analytics tools, data
        mining tools or similar tools etc.;<br />
        &nbsp;&nbsp;&nbsp;&nbsp; d) providing gambling information or inducing
        others to engage in gambling;<br />
        &nbsp;&nbsp;&nbsp;&nbsp; e) invading into others’ TokenPocket wallets to
        steal Tokens;<br />
        &nbsp;&nbsp;&nbsp;&nbsp; f) engaging in any inaccurate or false
        transactions with the counterparty;<br />
        &nbsp;&nbsp;&nbsp;&nbsp; g) committing any activities which harms or
        attempts to harm TokenPocket service system and data;<br />
        &nbsp;&nbsp;&nbsp;&nbsp; h) other activities which TPF has reason to
        believe are inappropriate.<br />
        8.3 You understand and accept that you shall be responsible for any
        violation of law (including but not limited to the regulations of the
        Customs and Tax) or for breach of this Agreement by you and shall
        indemnify TPF against the losses, the third-party claims or
        administrative penalties against TPF incurred by such violation or
        breach, including reasonable attorney’s fees.<br />
        8.4 You are not an Excluded Person who is eligible to use the
        TokenPocket service.<br />
        8.5 You confirm that you will pay the service fees charged by TPF in
        time (if applicable). TPF reserves the right to suspend the services
        when the User fails to pay service fees (if applicable).<br />
        <br
      /></b>
    </p>
    <h3>IX. Privacy Policy</h3>
    <p>
      Your privacy is of utmost importance for TPF. Please refer to TokenPocket
      Privacy Policy as updated by us from time to time for relevant privacy
      protection policies.
      <br /><br />
    </p>
    <h3>X. Disclaimer and Limitation of Liability</h3>
    <p>
      10.1 TPF only undertakes obligations expressly set forth in this
      Agreement.<br />
      10.2 YOU ACKNOWLEDGE AND ACCEPT THAT, TO THE MAXIMUM EXTENT PERMITTED BY
      APPLICABLE LAW, TokenPocket IS PROVIDED ON AN “AS IS”, “AS AVAILABLE” AND
      “WITH ALL FAULTS” BASIS. TPF shall not be held liable for malfunction of
      TokenPocket which results from the following reasons:<br />
      &nbsp;&nbsp;&nbsp;&nbsp; a) system maintenance or upgrading of
      TokenPocket;<br />
      &nbsp;&nbsp;&nbsp;&nbsp; b) force majeure, such as typhoon, earthquake,
      flood, lightning or terrorist attack etc.;<br />
      &nbsp;&nbsp;&nbsp;&nbsp; c) malfunction of your mobile device hardware and
      software, and failure of telecommunication lines and power supply
      lines;<br />
      &nbsp;&nbsp;&nbsp;&nbsp; d) your improper, unauthorized or unrecognized
      use of TPF services;<br />
      &nbsp;&nbsp;&nbsp;&nbsp; e) computer viruses, Trojan Horse, malicious
      program attacks, network congestion, system instability, system or
      equipment failure, telecommunication failure, power failure, banking
      issues, government acts etc.;<br />
      &nbsp;&nbsp;&nbsp;&nbsp; f) any other reasons not imputed to TPF.<br />
      10.3 TPF shall not be held liable under the following circumstances:<br />
      &nbsp;&nbsp;&nbsp;&nbsp; a) Users lose their mobile devices, delete
      TokenPocket applications and wallets without back-up, forget Wallet
      Passwords, Private Keys, Mnemonic Words, Keystores without back-up, which
      result in the loss of their Tokens;<br />
      &nbsp;&nbsp;&nbsp;&nbsp; b) Users disclose their Wallet Passwords, Private
      Keys, Mnemonic Words, Keystores, or lend or transfer their TokenPocket
      wallets to others, or authorize others to use their mobile devices or
      TokenPocket wallets, or download TokenPocket applications through
      unofficial channels, or use TokenPocket applications by other insecure
      means, which result in the loss of their Tokens;<br />
      &nbsp;&nbsp;&nbsp;&nbsp; c) Users mishandle TokenPocket (including but not
      limited to wrong address, failure of the node servers selected by you),
      which result in the loss of Tokens;<br />
      &nbsp;&nbsp;&nbsp;&nbsp; d) Users are unfamiliar with the knowledge of
      blockchain and their mishandling of TokenPocket results in loss of their
      Tokens;<br />
      &nbsp;&nbsp;&nbsp;&nbsp; e) TokenPocket is unable to copy accurate
      transaction records due to system delay or blockchain instability etc.;<br />
      10.4 Under the registration method using Tokenpocket mobile phone number
      and email address (the effective decentralized solution under the EOS
      system), users can choose to export the private key. The property damage
      arising from loss or stolen private key after the user changes the
      authority of Owner and Active shall not be borne by TPF in any way.<br />
      10.5 Under the default mode of the registration method using Tokenpocket
      mobile phone number and email address (the effective decentralized
      solution under the EOS system), if the private key cannot be retrieved
      through mobile phone number due to the user’s causes (such as the user
      cannot gain a new SIM card), TPF will not provide the service to retrieve
      the private key.<br />
      10.6 Property loss caused by users themselves (including but not limited
      to loss of mobile phone number, email address, the disclosure of the
      verification code, publication of the private key by the user, and the
      user’s personal information being hacked) shall not be borne by TPF in any
      way.<br />
      10.7 Users shall undertake the risks and consequences of their
      transactions on the third-party-developed DApps.You understand that
      TokenPocket is only an management tool for Tokens which is incapable to
      control the quality, security and legitimacy of products and services
      provided by the third-party-developed DApps, or the authenticity and
      accuracy of their information and their capabilities to fulfill the
      obligations under the agreements with you. You, at your sole discretion,
      decide whether to transact on the third-party-developed DApps. It is the
      third-party-developed DApps, instead of TokenPocket, that transact with
      you. We kindly remind you to carefully review the authenticity,
      legitimacy, and effectiveness of related information provided by the
      third-party-developed DApps before you decide to use the DApps. In
      addition, you shall also assume all the risks arising from the
      transactions between you and any third party exchanges.<br /><br />

      You acknowledge that TokenPocket may provide services to you and your
      counterparties simultaneously and you agree to waive any actual or
      potential conflicts of interests and will not claim against TPF on such
      base or burden TPF with more responsibilities or duty of care.<br />
      <br />
    </p>
    <h3>XI. TPF does not warrant that:</h3>
    <p>
      <b>
        a) services provided by TPF would satisfy all your needs;<br />
        b) all techniques, products, services, information or other materials
        from TPF would meet your expectations;<br />
        c) all the transaction information in digital tokens markets captured
        from the third party exchanges are prompt, accurate, complete, and
        reliable;<br />
        d) your counterparties on TokenPocket will perform their obligations in
        the transaction agreements with you timely.<br />
        e) in any case, the total liability for TPF under this Agreement shall
        not exceed the greater of:<br />
        &nbsp;&nbsp;&nbsp;&nbsp; a. 0.1 Ether; or<br />
        &nbsp;&nbsp;&nbsp;&nbsp; b. 500 SGD, higher value shall prevail.<br />
        11.1 You are aware that TokenPocket is only a tool for Users to manage
        their Tokens and to display transaction information. TPF does not
        provide legal, tax or investment advice. You shall seek advice from
        professional legal, tax, and investment advisors. In addition, TPF shall
        not be liable for any investment loss, data loss etc. during your use of
        our service.<br />
        11.2 You understand that we may change our entry standards, limit the
        range and ways to provide services for specific Users etc. at any time
        in accordance with relevant laws and regulations.<br />
        <br
      /></b>
    </p>
    <h3>XII. Entire Agreement</h3>
    <p>
      12.1 This Agreement incorporates TokenPocket Terms of Service, TokenPocket
      Privacy Policy, and other rules (including contents in the “Support”
      column) posted by TPF from time to time.<br />
      12.2 If any provision of this Agreement is found by a court with competent
      jurisdiction to be invalid, the other provisions of this Agreement remain
      in full force and effect.<br />
      12.3 This English version and other translated version of this Agreement
      (if any) are provided for the convenience of Users, and are not intended
      to revise the Chinese version of this Agreement. If there is any
      discrepancy between the Chinese version and non-Chinese version of this
      Agreement, the Chinese version shall prevail.<br />
      <br />
    </p>
    <h3>XIll. Intellectual Property Rights Protection</h3>
    <p>
      TokenPocket is an application developed and owned by TPF. The intellectual
      property rights of any contents displayed in TokenPocket (including this
      Agreement, announcements, articles, videos, audios, images, archives,
      information, materials, trademarks or logos) are owned by TPF or the third
      party licensors. Users can only use the TokenPocket applications and its
      contents for the purpose of holding and managing their Tokens. In
      particular, without prior written consent from TokenPocket or the third
      party licensors, no one shall use, modify, decompile, reproduce, publicly
      disseminate, alter, distribute, issue or publicly publish the
      abovementioned applications and contents.<br />
      <br />
    </p>
    <h3>XIV. Governing Law and Dispute Resolution</h3>
    <p>
      14.1 The validity, interpretation, alternation, enforcement, dispute
      resolution of this Agreement and its revised versions shall be governed
      and construed in accordance with the TPF's national laws. Where there is
      no applicable law, this Agreement shall be interpreted by applicable
      commercial and/or industrial practices.<br />
      14.2 If any dispute or claim in connection with this Agreement arises
      between you and TPF, the parties shall first attempt to resolve the
      dispute or claim through amicable negotiations in good faith. If the
      parties cannot reach an agreement, either party may sue the other party at
      the competent court where TPF is located.<br />
      <br />
    </p>
    <h3>XV. Miscellaneous</h3>
    <p>
      15.1 If you live outside of Singapore, you shall fully understand and
      conform to the laws, regulations and rules in your jurisdictions which are
      relevant to use of TPF services.<br />
      15.2 During your use of TPF services, if you come across any problems, you
      can contact us through the submission of your feedbacks on TokenPocket.<br />
      15.3 This Agreement is accessible for all Users on TokenPocket. We
      encourage you to read this Agreement each time you log onto
      TokenPocket.<br />
      <b
        >This Agreement shall become effective on January 10th, 2018.<br /><br />

        As for any issues not covered in this Agreement, you shall comply with
        the announcements and relevant rules as updated by TPF from time to
        time.<br /><br />

        TokenPocket Foundation Ltd.</b
      >
    </p>
  </div>
</template>
<script>
export default {
  props: {},
  data() {
    return {};
  },

  components: {},

  computed: {},

  mounted() {},

  methods: {},
  watch: {},
  destroyed() {},
};
</script>
<style lang='less' scoped>
.term {
  margin: 10px 0 30px;
  padding: 10px;
  color: #333;
  h1 {
    font-size: 1.2em;
    text-align: center;
    line-height: 3;
  }
  h2 {
    font-size: 0.8em;
    text-align: center;
    line-height: 2;
  }
  h3 {
    font-size: 1em;
    font-weight: bold;
    margin: 10px 0;
  }
  h4 {
    font-size: 0.8em;
    margin: 0 0 6px;
  }
  ul {
    list-style: disc;
    padding-left: 15px;
    line-height: 1.5;
  }
}
</style>