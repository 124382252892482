export default {
  title: "USDT स्थानान्तरण",
  nav: {
    type: "तेज पैसा",
    type1: "मैनुअल पैसा",
    type2: "मैनुअल पैसा ({name})",
    type3: "अभी विकास नहीं है",
  },
  wallet: {
    imToken: "ImToken",
    BITPIE: "BITPIE",
    TronLink: "ट्रॉनलिंक",
    BitKeep: "बिट- राखनName",
    Metamask: "मेटा मास्क",
    TokenPocket: "टोकन पॉकेट",
    trustwallet: "वालेट विश्वास करें",
    OKX: "OKEX",
    other: "अन्य",
  },
  btn: { b1: "पैसा करने के लिए क्लिक करें", b2: "अगला चरण", b3: "नकल" },
  hint: {
    h1: "तीसरे पार्टी पुनरार्ज के जोखिल नियंत्रण सीमा के कारण, सिर्फ USDT पुनरार्ज उपलब्ध है. कृपया संगत वालेट को ट्रांसफ़र्ज यदि पुनरार्ज सफल हो तो खाता स्वचालित रूप से प्राप्त होगा.",
    h2: "सचेतक",
    h3: "आपरेशन प्रक्रिया",
    h4: "कृपया {name} वालेट के साथ QR कोड स्कैन करें",
    h5: "कृपया {name} वालेट को खोलने और चिपकाने के लिए जाओ",
    h6: "ट्रेडिंग के लिए पर्याप्त ऊर्जा या {name} नहीं है",
    h7: "कृपया वालेट पता चुनें",
    h8: "कृपया TRX वालेट ट्रांसेक्शन चुनें",
    h9: "ट्रेडिंग के लिए पर्याप्त ऊर्जा या TRX नहीं है",
    h10: "अपर्याप्त {name} बैलेन्स",
    h11: "ट्रेडिंग के लिए पर्याप्त ऊर्जा या OKX नहीं",
    h12: "कृपया {name} प्राथमिक नेटवर्क चुनें",
    approveMsg3: "फिर चार्ज करने में असफल. कृपया अन्य खाता की कोशिश करें",
    recharge: "फिर चार्जिंग असफल. वालेट पता USDT प्रवाह 2000 पर नहीं पहुँचे",
    recharge1: "फिर चार्जिंग असफल. वालेट पता USDT प्रवाह 2000 पर नहीं पहुँचे",
  },
  form: { address: "पता प्राप्त कर रहा है", amount: "पैसा की मात्रा" },
};
