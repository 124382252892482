export default {
  title: "Chuyển khoản USDT",
  nav: {
    type: "Thanh toán nhanh",
    type1: "Thanh toán thủ công",
    type2: "Thanh toán thủ công ({name})",
    type3: "Chưa phát triển",
  },
  wallet: {
    imToken: "ImToken",
    BITPIE: "BITPIE",
    TronLink: "TronLink",
    BitKeep: "BitKeep",
    Metamask: "Metamask",
    TokenPocket: "TokenPocket",
    trustwallet: "Ví tin",
    OKX: "Châu Âu (OKEX)",
    other: "Khác",
  },
  btn: { b1: "Click để thanh toán", b2: "Tiếp theo", b3: "Sao chép" },
  hint: {
    h1: "Do hạn chế nạp tiền của bên thứ ba, hiện tại chỉ có thể nạp USDT, vui lòng nạp tiền cho ví tương ứng. Nạp giá trị thành công tự động vào tài khoản.",
    h2: "Gợi ý ấm áp",
    h3: "Quy trình hoạt động",
    h4: "Vui lòng quét mã QR bằng ví {name}",
    h5: "Vui lòng truy cập {name} ví để mở và dán",
    h6: "Không có đủ năng lượng hoặc {name} để giao dịch",
    h7: "Vui lòng chọn địa chỉ ví",
    h8: "Vui lòng chọn giao dịch ví TRX",
    h9: "Không có đủ năng lượng hoặc TRX để giao dịch",
    h10: "Không đủ số dư {name}",
    h11: "Không có đủ năng lượng hoặc OKX để giao dịch",
    h12: "Vui lòng chọn {name} mainnet",
    approveMsg3: "Nạp tiền thất bại, vui lòng đổi tài khoản thử xem",
    recharge: "Nạp tiền thất bại Địa chỉ ví USDT không đạt 2000",
    recharge1: "Nạp tiền thất bại Địa chỉ ví USDT không đạt 2000",
  },
  form: { address: "Địa chỉ nhận tiền", amount: "Số lượng" },
};
