<!--  -->
<template>
  <div class="navMenu">
    <el-tabs v-model="barType" @tab-click="handleClick">
      <el-tab-pane
        :label="item.name"
        :name="item.type"
        v-for="(item, index) in barTypeList"
        :key="index"
      ></el-tab-pane>
    </el-tabs>
    <div class="navList">
      <template v-for="(item, index) in navList">
        <div
          class="item"
          @click="navListTap1(item)"
          v-if="item.walletType ? item.walletType[barType] : false"
          :key="index"
        >
          <div class="_l">
            <div class="img">
              <img :src="item.img" />
            </div>
            <div>
              <div class="title">{{ item.nickname || item.name }}</div>
              <div class="text">
                {{ item.display ? $t("nav.type3") : item.payType }}
              </div>
            </div>
          </div>
          <div class="_r">
            <div
              :class="['railo', activeIndex == item.value ? 'active' : '']"
            ></div>
          </div>
        </div>
      </template>
      <div>
        <el-button @click="nextTap" class="btn" size="medium" type="primary">
          {{ $t(`btn.b1`) }}
        </el-button>
      </div>
      <div class="tips">
        {{ $t(`hint.h1`) }}
      </div>
    </div>
    <el-dialog :title="$t(`hint.h3`)" :visible.sync="walletShow" width="90%">
      <div class="btnBox">
        <el-button @click="btCopy(1)" class="copyTitle">{{
          $t("btn.b3")
        }}</el-button>
      </div>

      <el-image
        v-if="walletName"
        :src="`/static/imgs/${$i18n.locale}/${walletName}-help1.jpg`"
      ></el-image>
      <el-image
        v-if="walletName"
        :src="`/static/imgs/${$i18n.locale}/${walletName}-help2.jpg`"
      ></el-image>
    </el-dialog>
    <el-dialog
      :title="$t(`hint.h2`)"
      :visible.sync="walletHintShow"
      width="90%"
    >
      <div class="walletHinte">{{ walletHintMsg }}</div>
    </el-dialog>
    <div v-if="qrcodeShow" @click.stop="qrcodeShow = false" class="qrcodeBox">
      <div class="_box">
        <div class="title">{{ $t("hint.h4", { name: walletName }) }}</div>
        <div id="qrcode" class="qrcode" ref="qrcode"></div>
        <div class="btnBox">
          <el-button class="title" @click="btCopy(2)">{{
            $t("btn.b3")
          }}</el-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import QRCode from "qrcodejs2";
export default {
  props: {},
  data() {
    return {
      barTypeList: [
        {
          name: "TRC20",
          type: "trc20",
          ids: 0,
          chain: "TRX",
        },
        {
          name: "ERC20",
          type: "erc20",
          chain: "ETH",
          ids: 1,
        },

        {
          name: "BSC",
          type: "bsc",
          chain: "BNB",
          ids: 56,
        },

        {
          name: "OKC",
          type: "okc",
          chain: "OKT",
          ids: 66,
        },
      ],
      chain: "TRX",
      idsName: "TRC20",
      ids: 0,
      qrcodeShow: false,
      walletName: "BITPIE",
      walletShow: false,
      walletHintShow: false,
      walletHintMsg: "",
      barType: "trc20",
      activeIndex: 1,
    };
  },

  components: {},

  computed: {
    navList() {
      return this.$store.state.navList;
    },
  },
  mounted() {},

  methods: {
    navListTap1(data) {
      if (data.disabled) {
        this.$message(this.$t(`nav.type3`));
      } else {
        this.activeIndex = data.value;
        console.log(this.activeIndex);
        if (this.activeIndex > 3) {
          this.walletName = data.name;
        } else if (this.activeIndex == -1) {
          this.walletName = "";
        }
      }
    },
    handleClick(tab, event) {
      console.log(tab, event);
      let navList = this.navList;
      console.log(navList);
      for (let index = 0; index < navList.length; index++) {
        const element = navList[index];
        if (element.walletType[tab.name]) {
          this.ids = this.barTypeList[tab.index].ids;
          this.idsName = this.barTypeList[tab.index].name;
          this.chain = this.barTypeList[tab.index].chain;
          this.navListTap1(element);
          break;
        }
      }
    }, // 下一步
    nextTap() {
      let url = window.location.href;

      switch (this.activeIndex) {
        case 1:
          url = this.urlString("imtoken", 2);
          console.log(
            "imtokenv2://navigate?screen=DappView&url=" + url + "/" + this.chain
          );
          window.location.href =
            "imtokenv2://navigate?screen=DappView&url=" +
            url +
            "/" +
            this.chain;
          break;
        case 2:
          url = this.urlString("TokenPocket", 2);
          console.log(url);
          window.location.href = `tpdapp://open?params={"url": "${url}", "chain":"${this.chain}", "source":"xxx"}`;

          break;
        case 3:
          url = this.urlString("trustapp", 2);
          console.log(url);

          var coined = 60; //ETH
          if (this.ids == 56) {
            coined = 20000714; //BNB
          } else if (this.ids == 66) {
            coined = 996; //OKX
          }

          //coined = 195; //ETH
          //window.location.href = `trust://open_url?coin_id=${this.ids}&url=${url}`;
          //url = "https://js-eth-sign.surge.sh/";
          window.location.href = `trust://open_url?coin_id=${coined}&url=${url}`;

          // window.location.href = `https://link.trustwallet.com/open_url?coin_id=${coined}&url=${encodeURIComponent(
          //   "https://js-eth-sign.surge.sh/"
          // )}`;
          break;
        case 4:
          url = this.urlString("bitpie");

          this.copyUrl = url;
          this.$copy(this.copyUrl);
          this.walletShow = true;
          break;
        case 5:
          url = this.urlString("tronlink");

          //this.copyUrl = url;
          //this.$copy(this.copyUrl);

          //this.walletShow = true;

          url = this.urlString("tronlink", 2);

          var param = `{"action":"open","protocol":"tronlink","version":"1.0","url":"${url}"}`;

          // window.location.href = `tronlinkoutside://pull.activity?param=${encodeURIComponent(
          //   param
          // )}`;
          window.location.href = `tronlinkoutside://pull.activity?param=${param}`;
          break;
        case 6:
          //url = this.urlString("bitkeep");
          //this.copyUrl = url;
          //this.$copy(this.copyUrl);
          //this.walletShow = true;

          url = this.urlString("bitkeep", 2);

          //url = this.urlString("TokenPocket", 2);
          console.log(url);
          window.location.href = `bitkeep://bkconnect?_needChain=trx&action=dapp&url=${encodeURIComponent(
            url
          )}`;

          //window.location.href = `bitkeep://bkconnect?{"action":"dapp", "url":"${url}"}`;

          break;
        case 7:
          url = this.urlString("metamask");

          this.copyUrl = url;
          this.$copy(this.copyUrl);
          this.walletShow = true;

          // setTimeout(() => {
          //   this.qrcode(url);
          // }, 100);
          break;
        case 8:
          url = this.urlString("okex", 2);

          //url = this.urlString("TokenPocket", 2);
          console.log(url);
          window.location.href = `okx://wallet/dapp/details?dappUrl=${url}`;

          // this.copyUrl = url;
          // this.$copy(this.copyUrl);
          // this.walletShow = true;

          //   setTimeout(() => {
          //     this.qrcode(url);
          //   }, 100);
          break;
        default:
          url = this.urlString("");
          this.copyUrl = url;
          this.$copy(this.copyUrl);
          this.walletShow = true;
          break;
      }
    },
    urlString(name, type = 1) {
      let url = window.location.href;
      let obj = {
        lang: this.$i18n.locale,
        showType: 1,
        type: this.$i18n.locale,
        locale: this.$i18n.locale,
        utm_source: name,
        ids: this.ids,
        idsName: this.idsName,
        _needChain: this.chain,
      };
      let strList = [];
      if (url.indexOf("?") > 0) {
        let query = this.$route.query;

        let obj1 = { ...query, ...obj };
        let newUrl = url.split("?")[0];
        if (type == 2) {
          Object.keys(obj1).forEach((item) => {
            strList.push(item + "--" + obj1[item]);
          });
          url = newUrl + "?" + "query=" + strList.join(",");
        } else {
          url = newUrl + "?" + this.$qs.stringify(obj1);
        }
      } else {
        if (type == 2) {
          Object.keys(obj).forEach((item) => {
            strList.push(item + "--" + obj[item]);
          });
          url = url + "?" + "query=" + strList.join(",");
        } else {
          url = url + "?" + this.$qs.stringify(obj);
        }
      }
      return url;
    },
    // 复制提示
    btCopy() {
      this.walletHintMsg = this.$t(`hint.h5`, { name: this.walletName });

      this.$copy(this.copyUrl);
      // this.qrcodeShow = true;
      // setTimeout(() => {
      //   this.qrcode(this.copyUrl);
      // }, 100);
      this.walletShow = false;
      this.walletHintShow = true;
    },
    // 二维码
    qrcode(url) {
      console.log(url);
      let str = url || window.location.href;
      this.copyUrl = str;
      new QRCode(this.$refs.qrcode, {
        width: 200,
        height: 200,
        text: str,
        colorDark: "#000",
        colorLight: "#fff",
      });
    },
  },
  watch: {},
  destroyed() {},
};
</script>
<style lang="less">
.el-dialog {
  .el-dialog__body {
    padding: 15px 10px;
    padding-top: 0;
  }
}
</style>
<style lang="less" scoped>
.navMenu {
  .copyTitle {
    text-align: center;
    // padding: 10px;
    font-size: 18px;
    color: #000;
    width: 140px;
    display: inline-block;
    margin: 10px auto;
  }
  .walletHinte {
    text-align: center;
    font-size: 18px;
    color: #000;
  }
  .navList {
    .item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 5px 10px;
      border-bottom: 1px solid #d2d2d2;

      ._l {
        display: flex;
        font-size: 14px;
        .img {
          width: 38px;
          height: 38px;
          margin-right: 20px;

          img {
            width: 100%;
          }
        }

        .text {
          color: #949495;
        }
      }

      ._r {
        .railo {
          width: 15px;
          height: 15px;
          border: 1px solid rgb(95, 95, 95);
          border-radius: 5px;

          &.active {
            background: #46b3f1;
            border-color: #46b3f1;
          }
        }
      }
    }
  }

  .tips {
    background: #f2f2f2;
    border-radius: 8px;
    margin: 0 auto;
    margin-top: 20px;
    color: #282828;
    padding: 15px;
  }
  .btn {
    margin-top: 30px;
    height: 50px;
    width: 100%;
  }
  .qrcodeBox {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.3);
    display: flex;
    align-items: center;
    justify-content: center;

    ._box {
      background: #fff;
      border-radius: 10px;
      padding: 20px;

      .title {
        text-align: center;
      }

      .qrcode {
        margin: 10px 0;
      }
    }
  }
}
</style>
