<!--  -->
<template>
  <div class="footer">
    <div>
      <div>
        Copyright © 2021 Singapore TokenPocket Foundation Ltd. All rights
        reserved.
      </div>
      <div class="">
        <router-link class="link" :to="{ name: 'privacy' }"
          >Privacy Policy</router-link
        >
        <span>|</span>
        <router-link class="link" :to="{ name: 'terms' }"
          >Terms of Use</router-link
        >
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {},
  data() {
    return {};
  },

  methods: {},
  watch: {},
};
</script>
<style lang='less' scoped>
.footer {
  margin-top: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  border-top: 1px solid #eee;
  .link {
    color: #2c3e50;
    text-decoration: none;
  }
}
</style>