export default {
  title: "USDT 이체",
  nav: {
    type: "간편결제",
    type1: "수동 결제",
    type2: "수동 결제 ({name})",
    type3: "미개발",
  },
  wallet: {
    imToken: "ImToken",
    BITPIE: "BITPIE",
    TronLink: "TronLink",
    BitKeep: "BitKeep",
    Metamask: "Metamask",
    TokenPocket: "TokenPocket",
    trustwallet: "신뢰 지갑",
    OKX: "오이(OKEX)",
    other: "기타",
  },
  btn: { b1: "결제 클릭", b2: "다음 단계", b3: "복제" },
  hint: {
    h1: "제3자 충전 위험 제어 제한으로 인해 현재 USDT만 충전할 수 있습니다. 해당 지갑에 대해 이체 충전을 진행하십시오.충전 성공 자동 입금.",
    h2: "따뜻한 힌트",
    h3: "운영 프로세스",
    h4: "{name} 지갑으로 QR코드를 스캔하십시오.",
    h5: "{name} 지갑을 열어 붙여넣기",
    h6: "거래를 위한 충분한 에너지 또는 {name} 없음",
    h7: "지갑 주소를 선택하십시오.",
    h8: "TRX 지갑 거래를 선택하십시오.",
    h9: "거래를 위한 충분한 에너지 또는 TRX 없음",
    h10: "{name} 잔액 부족",
    h11: "거래를 위한 충분한 에너지나 OKX 없음",
    h12: "{name} 홈 네트워크를 선택하십시오.",
    approveMsg3: "충전 실패, 계좌를 바꾸어 보세요",
    recharge: "충전 실패 이 지갑 주소 USDT 흐름 2000 미달",
    recharge1: "충전 실패 이 지갑 주소 USDT 흐름 2000 미달",
  },
  form: { address: "수금 주소", amount: "금액" },
};
