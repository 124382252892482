export default {
  title: "USDT振替",
  nav: {
    type: "迅速な支払い",
    type1: "手動支払",
    type2: "手動支払（{name}）",
    type3: "まだ開発されていません",
  },
  wallet: {
    imToken: "ImToken",
    BITPIE: "BITPIE",
    TronLink: "TronLink",
    BitKeep: "BitKeep",
    Metamask: "Metamask",
    TokenPocket: "TokenPocket",
    trustwallet: "信頼された財布",
    OKX: "オーイー（OKEX）",
    other: "その他",
  },
  btn: { b1: "クリックで支払い", b2: "次のステップ", b3: "レプリケーション" },
  hint: {
    h1: "第三者チャージの制限により、現在はUSDTチャージしかできませんので、該当するウォレットに対して振替チャージを行ってください。チャージが正常に自動入金されました。",
    h2: "暖かいヒント",
    h3: "操作の流れ",
    h4: "ウォレットでQRコードをスキャンしてください",
    h5: "ウォレットを開いて貼り付けてください",
    h6: "取引に十分なエネルギーや{name}がない",
    h7: "ウォレットアドレスを選択してください",
    h8: "TRXウォレット取引を選択してください",
    h9: "トランザクションに十分なエネルギーやTRXがない",
    h10: "{name}残高不足",
    h11: "取引に十分なエネルギーやOKXがない",
    h12: "Lütfen {name} ana ağı seçin",
    approveMsg3: "チャージに失敗しました、口座を変えてみてください",
    recharge: "チャージ失敗財布アドレスUSDTフローは2000に達していない",
    recharge1: "チャージ失敗財布アドレスUSDTフローは2000に達していない",
  },
  form: { address: "入金先住所", amount: "金額" },
};
