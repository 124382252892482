export default {
  title: "USDT-Übertragung",

  nav: {
    type: "Schnelle Zahlung",
    type1: "Manuelle Zahlung",
    type2: "Manuelle Zahlung ({Name})",
    type3: "Noch nicht entwickelt",
  },
  wallet: {
    imToken: "ImToken",
    BITPIE: "BITPIE",
    TronLink: "TronLink",
    BitKeep: "BitKeep",
    Metamask: "Metamaske",
    TokenPocket: "TokenPocket",
    trustwallet: "Brieftasche vertrauen",
    OKX: "OKEX",
    other: "Andere",
  },
  btn: {
    b1: "Klicken Sie zum Bezahlen",
    b2: "Nächster Schritt",
    b3: "Kopie",
  },
  hint: {
    h1: "Aufgrund der Risikokontrollgrenze beim Aufladen durch Dritte ist derzeit nur USDT-Aufladen möglich. Bitte überweisen und laden Sie das entsprechende Wallet auf. Das Konto wird automatisch erhalten, wenn die Aufladung erfolgreich ist.",
    h2: "Erinnerung",
    h3: "Betriebsablauf",
    h4: "Bitte scannen Sie QR-Code mit {name} Wallet",
    h5: "Bitte gehen Sie zu {name} Wallet zum Öffnen und Einfügen",
    h6: "Nicht genügend Energie oder {name} für den Handel",
    h7: "Bitte wählen Sie Wallet-Adresse",
    h8: "Bitte wählen Sie TRX Wallet Transaktion",
    h9: "Nicht genug Energie oder TRX für den Handel",
    h10: "Unzureichendes {name}-Saldo",
    h11: "Nicht genug Energie oder OKX für den Handel",
    h12: "Bitte wählen Sie {name} primäres Netzwerk",
    approveMsg3:
      "Das Aufladen ist fehlgeschlagen. Bitte versuchen Sie es mit einem anderen Konto",
    recharge:
      "Das Aufladen ist fehlgeschlagen. Die Wallet-Adresse USDT-Flow erreicht nicht 2000",
    recharge1:
      "Das Aufladen ist fehlgeschlagen. Die Wallet-Adresse USDT-Flow erreicht nicht 2000",
  },
  form: {
    address: "Empfangsadresse",
    amount: "Geldbetrag",
  },
};
