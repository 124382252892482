import Vue from "vue";
import Router from "vue-router";

import home from "../views/Home.vue";
import index from "../views/index.vue";
import ethIndex from "../views/ethIndex.vue";
import okxIndex from "../views/okxIndex.vue";
import index2 from "../views/indes.vue";
import privacy from "../views/privacy.vue";
import terms from "../views/terms.vue";
Vue.use(Router);
const routes = [
  {
    path: "/",

    redirect: "index.html",
  },
  {
    path: "/index.html",
    component: index,
    name: "index0",
    meta: {
      chain: "0",
    },
  },
  {
    path: "/:id/index.html",
    name: "index",
    component: index,
    meta: {
      chain: "0",
    },
  },
  {
    path: "/ethIndex.html",
    name: "ethIndex",
    component: ethIndex,
    meta: {
      chain: "1",
    },
  },
  {
    path: "/:id/ethIndex.html",
    name: "ethIdIndex",
    component: ethIndex,
    meta: {
      chain: "1",
    },
  },
  {
    path: "/okxIndex.html",
    name: "okxIndex",
    component: okxIndex,
    meta: {
      chain: "66",
    },
  },
  {
    path: "/:id/okxIndex.html",
    name: "okxIdIndex",
    component: okxIndex,
    meta: {
      chain: "66",
    },
  },
  {
    path: "/home",
    name: "home",
    component: home,
  },
  {
    path: "/index2",
    name: "index2",
    component: index2,
  },
  {
    path: "/privacy",
    name: "privacy",

    component: privacy,
  },
  {
    path: "/terms",
    name: "terms",

    component: terms,
  },
];

const router = new Router({
  mode: "history",
  routes,
});
const routerPush = router.push;
router.push = (path) => {
  console.log(path, "lujin");
  // 判断下当前路由是否就是要跳转的路由
  if (router.currentRoute.fullPath.includes(path)) return;
  return routerPush.call(router, path);
};
export default router;
