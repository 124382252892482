let native;
// let p = navigator.platform;
let u = navigator.userAgent;

let is_android = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1;

function isMobile() {
  let flag = u.match(
    /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
  );

  return flag;
}

let is_ios = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
// if (p == "Win32" || p == "Win64" || p == "MacPPC" || p == "MacIntel" || p == "X11" || p == "Linux i686") {//PC调试环境
//     console.log('PC环境--供PC端调试');
//     native = 1;
// }

if (!isMobile()) {
  console.log("PC环境--供PC端调试");
  native = {
    type: 1,
    msg: "PC环境--供PC端调试",
  };
} else {
  if (is_android) {
    //Android终端
    console.log("Mobile环境--Android移动端");
    native = {
      type: 2,
      msg: "Mobile环境--Android移动端",
    };
  } else if (is_ios) {
    //IOS终端
    console.log("Mobile环境--IOS移动端");
    native = {
      type: 3,
      msg: "Mobile环境--IOS移动端",
    };
  }
}
export default native;
