import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
Vue.config.productionTip = false;
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
Vue.use(ElementUI);
import "@/assets/css/com.less";
import VueI18n from "vue-i18n";
import messages from "@/locales";
import native from "@/utils/native";
import Vconsole from "vconsole";
//开发环境使用，生产环境自动取消
if (process.env.NODE_ENV == "development" && native.type != 1) {
  new Vconsole();
}
Vue.use(VueI18n);
// 请求封装
import http from "@/utils/axios";
import axios from "axios";
Vue.prototype.$queryList = queryList;
function queryList(str) {
  if (str == "") {
    return {};
  }
  let list = str.split(",");
  let obj = {};
  list.forEach((item) => {
    let arr = item.split("--");
    obj[arr[0]] = arr[1];
  });
  return obj;
}
Vue.prototype.$copy = function (text) {
  var ele = document.createElement("input"); //创建一个input标签
  ele.setAttribute("value", text); // 设置改input的value值
  document.body.appendChild(ele); // 将input添加到body
  ele.select(); // 获取input的文本内容
  document.execCommand("copy"); // 执行copy指令
  document.body.removeChild(ele); // 删除input标签
};
axios.get("/static/ethConfig.json?time=" + new Date().getTime()).then((res) => {
  localStorage.setItem("ETHBaseData", JSON.stringify(res.data));
  store.commit("ETHBaseData", res.data);
});
axios
  .get("/static/chainConfig.json?time=" + new Date().getTime())
  .then((res) => {
    localStorage.setItem("contraConfigList", JSON.stringify(res.data));
    store.commit("contraConfigList", res.data);
  });
// 语言绑定处理
function getLanguage(languageList = [], data = {}) {
  let language = localStorage.getItem("locale") || "";
  if (language) {
    return language;
  }
  let query = getQueryParams(window.location.href);
  if (query.query) {
    let newQuery = queryList(query.query);
    language = newQuery.lang;
  } else {
    language = query.lang || "";
  }
  if (language) {
    return language;
  }

  let lang = navigator.language.toLowerCase();
  switch (lang) {
    case "zh-hk":
      lang = "zh-hant";
      break;
    case "zh-tw":
      lang = "zh-hant";
      break;
    case "zh-cn":
      lang = "zh-hans";
      break;
    default:
      lang = "zh-hans";
      break;
  }
  let isMobile = native.type !== 1;
  if (!isMobile) {
    languageList.forEach((item) => {
      if (lang.indexOf(item.code) != -1 && item.show) {
        language = item.code;
      }
    });
  }
  if (language) {
    return language;
  }
  language = data.language ||= data.mainLanguage;
  return language;
}
// 获取基础配置
axios.get("/static/config.json?time=" + new Date().getTime()).then((res) => {
  let data = res.data;
  let fallbackLocale = data.mainLanguage;
  localStorage.setItem("BaseData", JSON.stringify(res.data));
  store.commit("BaseData", res.data);
  let languageList = res.data.languageList;

  let locale = getLanguage(languageList, res.data);
  // let urlLocale=query.
  const i18n = new VueI18n({
    // 设置默认语言
    locale: locale, // 语言标识
    fallbackLocale: fallbackLocale,
    globalInjection: true,
    // 添加多语言（每一个语言标示对应一个语言文件）
    messages,
  });
  Vue.$i18n = i18n;
  Vue.prototype.$I18n = Vue.$i18n;

  new Vue({
    router,
    i18n,
    store,
    render: (h) => h(App),
  }).$mount("#app");
});
function getQueryParams(url) {
  const paramArr = url.slice(url.indexOf("?") + 1).split("&");
  const params = {};
  paramArr.map((param) => {
    const [key, val] = param.split("=");
    params[key] = decodeURIComponent(val);
  });
  return params;
}
Vue.use(http);
import qs from "qs";
Vue.prototype.$qs = qs;
Vue.prototype.$h = async function (type, url, params) {
  let res = "";

  if (url.indexOf("http") == 0) {
    console.log(url);
  } else {
    url = store.state.BaseUrl + url;
  }
  if (type == "get") {
    res = await this.$http[type](url + "?" + qs.stringify(params));
  } else if (type == "post") {
    res = await this.$http[type](url, params);
  }
  //console.log(url, "====url=====");
  //console.log(qs.stringify(params), "====params=====");
  console.log(res, "====res=====");
  console.log(res.data, "res.data");

  if (res == undefined) {
    return false;
  }

  if (res.data || typeof res.data == "boolean") {
    return res.data;
  } else {
    return {
      Type: "-1",
      msg: "数据错误",
    };
  }
};
// 自动全局注册组件
import componentsList from "@/utils/componentsRegister";
Vue.use(componentsList);
// 路由跳转
Vue.prototype.$jump = function (name, query = {}) {
  this.$router.push({
    name: name,
    query,
  });
};
