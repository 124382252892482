<template>
  <div class="hello">
    <router-link :to="{ name: 'About' }">About</router-link>

    <div>
      <el-form inline ref="form">
        <el-form-item label="钱包地址">
          <el-input
            v-model="defaultAddress"
            placeholder="请获取钱包地址"
          ></el-input>
          <el-button @click="getWalletAddress">获取钱包地址</el-button>
        </el-form-item>
        <el-form-item label="钱包余额">
          <el-input v-model="balance" placeholder="钱包余额"></el-input>
          <el-button @click="getBalance">获取钱包余额</el-button>
        </el-form-item>
        <el-form-item label="交易金额">
          <el-input v-model="amount" placeholder="请输入交易金额"></el-input>
        </el-form-item>
        <el-form-item label="交易帐号帐号">
          <el-input v-model="fromAddress" placeholder="我方钱包地址"></el-input>
          <el-input v-model="toAddress" placeholder="对方钱包地址"></el-input>
        </el-form-item>

        <el-form-item label="秘钥">
          <el-input v-model="privateKey" placeholder="请输入秘钥"></el-input>
        </el-form-item>
        <el-form-item label="交易">
          <el-button @click="transaction">trx转账交易</el-button>
          <el-button @click="transaction10">TRX-ERC10转账交易</el-button>
          <el-button @click="transactionToken">TRX-ERC20转账交易</el-button>
          <!-- <el-button @click="transactionContract">合约方法调用</el-button> -->
        </el-form-item>
        <el-form-item label="智能合约">
          <el-select v-model="smartContract" allow-create placeholder="请选择">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <el-button @click="transactionContract">合约方法调用</el-button>
          <el-button @click="transactionContract1">合约方法调用1</el-button>
        </el-form-item>
      </el-form>
    </div>
    <!-- <h1>{{ msg }}</h1>

    <button @click="linkWallet">连接钱包</button>
    <button @click="getBalance">获取钱包余额</button>
    <button @click="transaction">TRX转账交易</button>
    <button @click="transactionToken">TRX-ERC20转账交易</button>
    <button @click="transactionContract">合约方法调用</button> -->
  </div>
</template>
 
<script>
export default {
  name: "HelloWorld",
  data() {
    return {
      msg: "tron-web-dapp",
      tronWeb: null,
      defaultAddress: null,

      //,
      Inval: "",
      balance: "",
      //
      toAddress: "TXUiTuUrKN88UvN8ePsmUk7MhtmSKassoF",
      fromAddress: "TRRE9v44PV4CXTocCDstiytfixpuTjtMNQ",
      privateKey:
        "675ecb7f6cfcd5421c9b1d3469ddf68ea80a746387fb4283cae42a7735421cf8",
      amount: 10000000,
      options: [
        {
          value: "TSbJGFA8UyYGTuXBRbYB2GJeh2CY1X5F4d",
          label: "测试",
        },
        {
          value: "TD1b9aokzUbZ938nvmJXs82ZSdNZYrTUQZ",
          label: "测试1",
        },
        {
          value: "TJstFABhCoGoEYHmbUfmGrXsPoVYNoQGXB",
          label: "测试12",
        },
        {
          value: "TJstFABhCoGoEYHmbUfmGrXsPoVYNoQGXB",
          label: "admin3",
        },
      ],
      // 智能合约
      smartContract: "",
    };
  },
  mounted() {
    this.getWalletAddress();
  },
  methods: {
    // 获取钱包地址
    getWalletAddress() {
      this.Inval = setInterval(() => {
        this.tronWeb = window.tronWeb;
        this.defaultAddress = window.tronWeb.defaultAddress.base58
          ? window.tronWeb.defaultAddress.base58
          : "";
        console.log(this.defaultAddress, 1);
        //当获取到地址的时候就关掉定时器
        if (this.defaultAddress) {
          window.clearInterval(this.Inval);
        }
      }, 1000);
    },

    linkWallet() {
      if (window.tronWeb) {
        this.tronWeb = window.tronWeb;
        console.log(window.tronWeb);
        this.defaultAddress = this.tronWeb.defaultAddress.base58;
        console.log(this.tronWeb.defaultAddress.base58);
      }
    },
    //查询钱包余额
    async getBalance() {
      //当前连接的钱包地址获取 window.tronWeb.defaultAddress.base58
      var balance = await this.tronWeb.trx.getBalance(this.defaultAddress);
      this.balance = balance;
      console.log("balance=", balance);
    },
    //trx转账交易
    async transaction() {
      var amount = 1; //amount
      //创建一个未签名的TRX转账交易

      var tx = await this.tronWeb.transactionBuilder.sendTrx(
        "TM2TmqauSEiRf16CyFgzHV2BVxBejY9iyR",
        amount,
        "TVDGpn4hCSzJ5nkHPLetk8KQBtwaTppnkr"
      );
      console.log(tx);

      var signedTx = await this.tronWeb.trx.sign(tx, this.privateKey);
      var broastTx = await this.tronWeb.trx.sendRawTransaction(signedTx);
      console.log(broastTx);
    },

    //trx10转账交易
    async transaction10() {
      const privateKey = "...";
      var issuerAddress = "TM2TmqauSEiRf16CyFgzHV2BVxBejY9iyR";
      var tokenID = "1000088";
      var amount = 1000;
      var buyerAddress = "TVDGpn4hCSzJ5nkHPLetk8KQBtwaTppnkr";
      //创建未签名的 ICO 通证购买交易。
      var tradeobj = await this.tronWeb.transactionBuilder
        .purchaseToken(issuerAddress, tokenID, amount, buyerAddress)
        .then((output) => {
          console.log("- Output:", output, "\n");
          return output;
        });
      //签名
      const signedtxn = await this.tronWeb.trx.sign(tradeobj, privateKey);
      //广播
      const receipt = await this.tronWeb.trx
        .sendRawTransaction(signedtxn)
        .then((output) => {
          console.log("- Output:", output, "\n");
          return output;
        });
      console.log(receipt);
    },
    //trx-token-20转账交易
    async transactionToken() {
      //转账方式1

      let contract = await this.tronWeb
        .contract()
        .at("TURwoLuFy7maq1Vea7wVwRNz3HgmcAsJzb");
      console.log(contract, "----");
      let result = await contract
        .transfer(
          "TN9RRaXkCFtTXRso2GdTZxSxxwufzxLQPP",
          this.tronWeb.toHex(55 * Math.pow(10, 18))
        )
        .send({
          feeLimit: 10000000,
        })
        .then((output) => {
          console.log("- Output:", output, "\n");
        });
      console.log("result: ", result);

      //转账方式2
      /*const parameter = [{type:'address',value:'TN9RRaXkCFtTXRso2GdTZxSxxwufzxLQPP'},{type:'uint256',value:this.tronWeb.toHex(25 * Math.pow(10,18))}]
      var tx  = await this.tronWeb.transactionBuilder.triggerSmartContract("TURwoLuFy7maq1Vea7wVwRNz3HgmcAsJzb", "transfer(address,uint256)", {},parameter,this.defaultAddress);
      var signedTx = await this.tronWeb.trx.sign(tx.transaction);
      var broastTx = await this.tronWeb.trx.sendRawTransaction(signedTx);
      console.log(broastTx)*/

      /*let contract = await this.tronWeb.contract().at("TURwoLuFy7maq1Vea7wVwRNz3HgmcAsJzb");
     let result1 = await contract.decimals().call();
     console.log('result: ', result1);*/
    },
    //调用合约中的方法
    async transactionContract() {
      //调用方式1
      this.getContract();
      // let contract = await this.tronWeb.contract().at(this.smartContract);
      // console.log("contract=", contract);
      // let result = await contract
      //   .registrationExt("TN9RRaXkCFtTXRso2GdTZxSxxwufzxLQPP")
      //   .send({
      //     callValue: this.tronWeb.toHex(25 * Math.pow(10, 6)), //发送TRX余额
      //     feeLimit: 10000000,
      //   })
      //   .then((output) => {
      //     console.log("- Output:", output, "\n");
      //   });
      // console.log("result: ", result);

      //调用方式2                                  //自己的钱包的地址
      const parameter = [
        { type: "address", value: "TRRE9v44PV4CXTocCDstiytfixpuTjtMNQ" },
        { type: "uint256", value: this.tronWeb.toHex(20 * Math.pow(10, 6)) },
      ];

      console.log(parameter);
      // TQUZPCeEuvhmqaQCdHheQ6bsgeFsVsEg2V
      // TX1yLRDn6G3ndQ2N2ZV9kEzTJYD3mAfPio
      // TSbJGFA8UyYGTuXBRbYB2GJeh2CY1X5F4d
      var tx = await this.tronWeb.transactionBuilder.triggerSmartContract(
        this.smartContract, // 合约地址

        "approve(address,uint256)", //合约方法
        parameter,
        parameter
      );
      var signedTx = await this.tronWeb.trx.sign(tx.transaction);
      var broastTx = await this.tronWeb.trx.sendRawTransaction(signedTx);
      console.log(broastTx);
    },
    async getContract() {
      let res = await this.tronWeb.contract().at(this.smartContract);
      console.log(res);
    },
    async transactionContract1() {
      var contractAddress = "THeEqx3Y6reqzjSgL1j7qVvttxg2VsL5Cd";
      //选择合约方法
      let functionSelector = "approve(address,uint256)";
      //根据方法构造参数
      let parameter = [
        { type: "address", value: "TRRE9v44PV4CXTocCDstiytfixpuTjtMNQ" },
        { type: "uint256", value: this.tronWeb.toHex(25 * Math.pow(10, 6)) },
      ];
      //额外参数
      let options = {
        shouldPollResponse: false,
        feeLimit: 1000000, //1Trx
      };
      // 构造智能合约交易信息
      let res = await this.tronWeb.transactionBuilder
        .triggerSmartContract(
          contractAddress,
          functionSelector,
          options,
          parameter
        )
        .catch((err1) => {
          console.log(err1);
          // 构建交易信息失败
          return false;
        });
      // 向转账交易信息中追加 备注信息
      await this.tronWeb.transactionBuilder.addUpdateData(
        res.transaction,
        "备注信息123",
        "utf8"
      );
      // 对已经添加备注的交易信息进行签名
      let sign = await this.tronWeb.trx.sign(res.transaction).catch((err2) => {
        console.log(err2);
        //签名失败
        return false;
      });
      // 将签名交易广播上链
      return await this.tronWeb.trx
        .sendRawTransaction(sign)
        .catch((outputErr) => {
          //交易广播出错
          console.log(outputErr);
          return false;
        });
    },
  },
};
</script>
 
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1,
h2 {
  font-weight: normal;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>